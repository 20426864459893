import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { startCase, toLower, isEmpty, capitalize } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';
import { paymentStatus, VERIFYME_TYPE, DATE_FORMAT, TIME_FORMAT } from './constants';

export const converToNumber = (val) => (val ? parseInt(val, 10) : 0) || 0;

export const getIdProofType = (data) => {
  let idProofType = 0;
  if (data?.bank_verification_no) {
    idProofType = 2;
  } else if (data?.national_identity_no) {
    idProofType = 1;
  }
  return idProofType;
};

export const getRegYearType = (data) => {
  let regYearType = 0;
  if (data?.registration_application_status) {
    regYearType = 1;
  } else if (data?.ctc_cac_1_1) {
    regYearType = 2;
  } else if (data?.cac_2 || data?.cac_7) {
    regYearType = 3;
  }

  return regYearType;
};

export const getTotalShareVal = (data) => {
  let val = 0;
  if (data?.shareholders?.length) {
    data.shareholders.forEach((sh) => {
      const percentage = converToNumber(sh.share_percentage);
      val += percentage;
    });
  }
  return val;
};

export const extractUserDocs = (data) => {
  const docs = {};
  data?.documents?.forEach((doc) => {
    docs[doc['document_name']] = doc['document_value'];
  });
  return docs;
};

export const formatCreateUpdateJobInitVals = (data) => {
  let formInitialValues = {};

  console.log('>> data', data);

  if (!isEmpty(data)) {
    let vehicleType = false;
    let roroType = false;
    let generalType = false;
    let generalContainerType = false;
    let ContainerType = false;
    let clearingJob = false;
    let forwardingJob = false;
    let jobType = false;
    let generalBulkCargoType = false;

    const defaultVehicle = [
      {
        brand: '',
        model: '',
        year: '',
        id: uuidv4(),
        vehicle_state: '',
        vreg_no: '',
      },
    ];

    const defaultContainers = [
      {
        vehicles: [
          {
            brand: '',
            model: '',
            year: '',
            id: uuidv4(),
            vehicle_state: '',
            vreg_no: '',
          },
        ],
        roroNoOfvechile: 1,
        id: uuidv4(),
        load_description: '',
      },
    ];

    let containers = [];
    if (data.category === 1 && data.type === 1) {
      containers =
        data.vehicles?.length > 0
          ? [
              {
                vehicles: data.vehicles.map((v) => {
                  return {
                    brand: v.brand || '',
                    model: v.model || '',
                    year: v.year || '',
                    vehicle_state: v.vehicle_state || '',
                    vreg_no: v.vreg_no || '',
                    id: v.uuid || uuidv4(),
                  };
                }),
                roroNoOfvechile: 1,
                id: uuidv4(),
                load_description: '',
              },
            ]
          : defaultVehicle;
    } else {
      containers =
        data.containers?.length > 0
          ? data.containers.map((c) => {
              let vehiclesDetails = [];

              if (c.vehicles?.length > 0) {
                vehiclesDetails = c.vehicles.map((v) => {
                  return {
                    brand: v.brand || '',
                    model: v.model || '',
                    year: v.year || '',
                    vehicle_state: v.vehicle_state || '',
                    vreg_no: v.vreg_no || '',
                    id: v.uuid || uuidv4(),
                  };
                });
              } else {
                vehiclesDetails = defaultVehicle;
              }

              return {
                vehicles: vehiclesDetails,
                roroNoOfvechile: c.vehicles?.length || '',
                id: c.uuid || uuidv4(),
                load_description: c.load_description || '',
              };
            })
          : defaultContainers;
    }

    if (!isEmpty(data?.job_type_tags) && data?.job_type_tags?.find((t) => t === 'clearing')) {
      clearingJob = true;
    }
    if (!isEmpty(data?.job_type_tags) && data?.job_type_tags?.find((t) => t === 'forwarding')) {
      forwardingJob = true;
    }

    if (data.category === 1) {
      vehicleType = true;
      roroType = true;
      generalType = false;
      generalContainerType = false;
      ContainerType = false;
      clearingJob = true;
      forwardingJob = false;
      generalBulkCargoType = false;

      // container job
      if (data.type === 2) {
        forwardingJob = true;
      }
    } else if (data.category === 2) {
      vehicleType = false;
      roroType = false;
      generalType = true;
      clearingJob = true;
      forwardingJob = true;
      generalContainerType = true;
      ContainerType = true;
      generalBulkCargoType = false;
    } else if (data.category === 3) {
      vehicleType = false;
      roroType = false;
      generalType = false;

      generalContainerType = true;
      ContainerType = false;
      generalBulkCargoType = true;
      clearingJob = true;
      // for bulk & wet type job
      if (data.category === 3 && data.type_of_cargo === 1) {
        forwardingJob = false;
      } else {
        forwardingJob = true;
      }
    }

    // for displaying 'Item Name' field
    let generalTypeOfcargoSingle = false;
    if ((data.category === 2 && data?.type_of_cargo === 1) || data.category === 3) {
      generalTypeOfcargoSingle = true;
    }

    // distingush documents for roro & other category
    let roroBillLading = '';
    let roroPAAR = '';
    let generalBillLading = '';
    let generalPAAR = '';
    if (data.category === 1) {
      roroBillLading = data.lading_link || '';
      roroPAAR = data.paar_link || '';
    } else {
      generalBillLading = data.lading_link || '';
      generalPAAR = data.paar_link || '';
    }

    // logic related to forwarding only job
    if (data.clearing_status == 11) {
      forwardingJob = true;
      clearingJob = false;
    }
    // set jobType when either or clearing or forwarding is true
    jobType = forwardingJob || clearingJob;

    formInitialValues = {
      jobId: data.uuid,
      forwardingJob,
      clearingJob,

      category: data.category ? data.category.toString() : '',
      vehicleType,
      generalType,

      // Vechile Data
      vehical_type: data.type ? data.type.toString() : '',
      roroType,
      vehicalContainerType: false,

      // Vehcile RORO Data
      roroNoOfvechile: 1,
      roroNoOfcontainer: data.no_of_containers || 1,
      dateOfArrival: data.date_of_arrival ? data.date_of_arrival?.split(' ')[0] : '',
      roroBillLading,
      roroPAAR,

      containers,

      // Forwarding service Data
      forwardingLocation: data.port_of_discharge || '',
      forwardingDestination: data.destination || '',

      // general Cargo Data
      generalContainerType,
      ContainerType,
      generalBulkCargoType,

      // general Container Data
      generalNoOfContainer: data.no_of_containers || '',
      generalTypeOfcargo: data.type_of_cargo ? data.type_of_cargo.toString() : '',
      generalTypeOfcargoSingle,
      generalItemName: data.single_item_title || '',
      generalBillLading,
      generalPAAR,

      // Bulk Cargo
      bulkTonnage: data.tonnage || '',

      performa_invoice: data.performa_invoice || '',
      forwardingDestinationState: data.destination_state || '',
      jobType,
      clearingDischarge: data.clearing_discharge_port,
    };
  } else {
    formInitialValues = {
      forwardingJob: false,
      clearingJob: true,

      category: '1',
      vehicleType: true,
      generalType: false,

      // Vechile Data
      vehical_type: '1',
      roroType: true,
      vehicalContainerType: false,

      // Vehcile RORO Data
      roroNoOfvechile: 1,
      roroNoOfcontainer: 1,
      dateOfArrival: '',
      roroBillLading: '',
      roroPAAR: '',

      containers: [
        {
          vehicles: [
            {
              brand: '',
              model: '',
              year: '',
              id: uuidv4(),
              vehicle_state: '',
              vreg_no: '',
            },
          ],
          roroNoOfvechile: 1,
          id: uuidv4(),
          load_description: '',
        },
      ],

      // Forwarding service Data
      forwardingLocation: '',
      forwardingDestination: '',

      // general Cargo Data
      generalContainerType: false,
      ContainerType: false,
      generalBulkCargoType: false,

      // general Container Data
      generalNoOfContainer: '',
      generalTypeOfcargo: '',
      generalTypeOfcargoSingle: false,
      generalItemName: '',
      generalBillLading: '',
      generalPAAR: '',

      // Bulk Cargo
      bulkTonnage: '',

      performa_invoice: '',
      forwardingDestinationState: '',
      jobType: true,
      clearingDischarge: '',
    };
  }

  return formInitialValues;
};

export const cleanObject = (obj) => {
  // it will remove null, empty sting and undefined values from an object
  const originalObj = obj;
  Object.keys(obj).forEach(
    (k) =>
      (originalObj[k] === null || originalObj[k] === '' || typeof originalObj[k] === 'undefined') &&
      delete originalObj[k],
  );
  return originalObj;
};

export const getPaymentStatus = (val) => {
  return paymentStatus[val];
};

export const getPaymentClassName = (val) => {
  let className = '';
  switch (val) {
    case '1': {
      className = 'alert-success';
      break;
    }
    case '2': {
      className = 'alert-danger';
      break;
    }
    case '3': {
      className = 'alert-warning';
      break;
    }
    case '4': {
      className = 'alert-warning';
      break;
    }
    default: {
      className = 'alert-info';
      break;
    }
  }
  return className;
};

export const weightFormat = (weight) => {
  if (!weight) return;
  let unit = 'tons';
  if (weight === 1) {
    unit = 'ton';
  }

  return weight + ' ' + unit;
};

export const formatAmount = (amount) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat(undefined, {
    // style: 'currency',
    // currency: 'NGN',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return formatter.format(amount); /* $2,500.00 */
};

export const displayAmount = (amount, dollar = false) => {
  const amt = formatAmount(amount);
  if (dollar) {
    return <>&#36;{amt}</>;
  }
  return <> &#8358;{amt}</>;
};

export const toTitleCase = (str) => startCase(toLower(str));

export const accessGeneralOrCargoType = (data, serviceData) => {
  let type = '';
  if (data?.category === 2) {
    type = toTitleCase(serviceData.job.type_of_general[data.type_of_cargo] || '');
  } else if (data?.category === 3) {
    type = toTitleCase(serviceData.job.type_of_cargo[data.type_of_cargo] || '');
  }
  return type;
};

export const getDisputeStatusClassname = (status, classnames) => {
  // 1: "Under Review By Operator"
  // 2: "Approved By Operator"
  // 3: "Rejected By Operator"
  // 4: "Approved By Compliance Officer"
  // 5: "Rejected By Compliance Officer"
  // 6: "Resolved By Compliance Officer"
  let cn = 'p-2 alert ';
  switch (status) {
    case 1:
      cn += 'alert-info';
      break;
    case 2:
      cn += 'alert-warning';
      break;
    case 3:
      cn += 'alert-danger';
      break;
    case 4:
      cn += 'alert-warning';
      break;
    case 5:
      cn += 'alert-danger';
      break;
    case 6:
      cn += 'alert-success';
      break;
    default:
      cn += 'alert-info';
      break;
  }
  return cn + ' ' + classnames;
};

export const getName = (data) => {
  const name = [];
  if (data?.first_name) name.push(data.first_name);
  if (data?.last_name) name.push(data.last_name);
  if (data?.type === 'clearing_agent' || data?.type === 'forwarding_agent')
    name.push(data.company_name);
  return name.join(' ');
};

export const getCopyrightYear = () => {
  return new Date().getFullYear();
};

export const isMobile = () => {
  return window.matchMedia('(max-width: 540px)');
};

// this method is used to check is environment is production
// this is done because the app is static hosted and there is no server running to check from process.env.REACT_APP_STAGE
export const isProd = () => {
  const PROD_HOST = 'goagent.com.ng';
  return window.location.host === PROD_HOST;
};

export const calculatePercentage = (percent, num) => (percent / 100) * num;

export const getAccountStatus = (data) => {
  const verified = 'Verified';
  const notVerified = 'Not Verified';
  return data?.identity_verified ? verified : notVerified;
};

export const mergeContainersToJobs = (data) => {
  if (!data?.length > 0) return [];
  const jobs = [];
  let temp = null;
  data.forEach((p, index) => {
    /**
     * >> algorithm for unbundle & merge
     * if mergejob is true and temp is empty save that item to temp;
     * continue;
     * if mergejob is true and temp is not empty then merge the current with temp item and create a job;
     * continue;
     * if mergejob if true and temp is empty and item is the last one then create a job;
     * continue;
     * if mergejob is false create a job for that item;
     * continue;
     */

    if (p.mergejob) {
      if (temp) {
        // if mergejob is true and temp is not empty then merge the current with temp item and create a job;
        jobs.push({
          size: '2', // '2' is for 40 ft = 20ft + 20ft
          weight: p.weight + temp.weight,
          containers: [p.id, temp.id],
        });
        // clear temp
        temp = null;
      } else {
        // if mergejob if true and temp is empty and item is the last one then create a job;
        if (index === data.length - 1) {
          jobs.push({
            size: p.size,
            weight: p.weight,
            containers: [p.id],
          });
        } else {
          // if mergejob is true and temp is empty save that item to temp;
          temp = p;
        }
      }
    } else {
      if (temp) {
        if (index === data.length - 1) {
          // if mergejob is false and temp is not empty and it is the last item create a job for temp;
          jobs.push({
            size: temp.size,
            weight: temp.weight,
            containers: [temp.id],
          });
          temp = null;

          // and also create a job for the current item;
          jobs.push({
            size: p.size,
            weight: p.weight,
            containers: [p.id],
          });
        } else {
          // and also create a job for the current item;
          jobs.push({
            size: p.size,
            weight: p.weight,
            containers: [p.id],
          });
        }
      } else {
        // if mergejob is false create a job for that item;
        jobs.push({
          size: p.size,
          weight: p.weight,
          containers: [p.id],
        });
      }
    }
  });
  return jobs;
};

export const getRiskPercentage = (natureOfGoods, serviceData) => {
  // natureOfGoods
  // 1: "High"
  // 2: "Medium"
  // 3: "Low"
  switch (natureOfGoods) {
    case '1':
      return serviceData?.settings?.general?.high_risk_goods;
    case '2':
      return serviceData?.settings?.general?.medium_risk_goods;
    case '3':
      return serviceData?.settings?.general?.low_risk_goods;
    default:
      return 0;
  }
};

export const getUserType = (serviceData, user) => {
  let type = '';

  // this is because
  // type for client always will be 'application_client'
  // and for user types like corporate, freezone, government we are saving that type in sub_type field
  // and these types come under application_corporate_client
  if (user.sub_type !== 'application_client') {
    type = 'application_corporate_client';
  } else {
    type = user.type;
  }
  return serviceData?.user_types?.[type];
};

export const getErrorClassNames = (error) =>
  classnames('form-control', {
    'is-invalid': !!error,
  });

export const addQueryParamToUrl = (history, param, value) => {
  if (history?.replace) {
    const url = new URL(window.location.href);
    if (url.searchParams.has(param)) {
      // replace param value
      url.searchParams.set(param, value);
    } else {
      // Add nwe parameter.
      url.searchParams.append(param, value);
    }
    history.replace(url.search);
  }
};

export const getQueryParamValue = (location, param) => {
  let paramVal = '';
  if (location?.search) {
    const url = new URL(window.location.href);
    paramVal = url.searchParams.get(param);
  }
  return paramVal || '';
};

export const checkVeifymeVerified = (user) => {
  // console.log({ user });
  if (user) {
    const veifymeDataExists = user?.verify_me_data?.find((vd) => {
      if (!isProd() && vd?.type === VERIFYME_TYPE && vd?.data?.nin === user?.mobile_number) {
        return true;
      } else if (vd?.type === VERIFYME_TYPE && vd?.data?.phone === user?.mobile_number) {
        return true;
      } else {
        return false;
      }
    });
    // console.log({ veifymeDataExists });
    if (veifymeDataExists) return true;
  }
  return false;
};

const getFullName = (user) => `${capitalize(user.first_name)} ${capitalize(user.last_name)}`;

export const getClientName = (client) => {
  const cType = client.sub_type || client.type;
  let name = '';
  if (cType === 'application_client') {
    name = getFullName(client);
  } else {
    name = capitalize(client.company_name);
  }
  return name || 'N/A';
};

export const getClientAddress = (client) => {
  const cType = client.sub_type || client.type;
  let address = '';
  if (cType === 'application_client') {
    address = client.address;
  } else {
    address = client.company_address;
  }
  return address || 'N/A';
};

export const getUserName = (user) => {
  let name = '';
  if (user.type === 'clearing_agent' || user.type === 'forwarding_agent') {
    name = capitalize(user?.company_name);
  } else if (
    user.type === 'managenmnt_operator' ||
    user.type === 'truck_inspector' ||
    user.type === 'truck_inspector' ||
    user.type === 'legal_lawer' ||
    user.type === 'work_supervisor'
  ) {
    name = getFullName(user);
  } else {
    name = getClientName(user);
  }
  return name || 'N/A';
};

export const _dateFormatter = (data) => moment(data).format(DATE_FORMAT);
export const _timeFormatter = (data) => moment(data).format(TIME_FORMAT);

export const getJobServices = (data) => {
  if (!data) return '';
  if (data?.job_type_tags?.length === 2) {
    return 'Clearing & Transport Service';
  } else {
    return data?.job_type_tags?.map((y, i) => (
      <p key={i}>{capitalize(y === 'forwarding' ? 'Transport' : y)} Service</p>
    ));
  }
};

export const getJobStatus = (job, serviceData) => {
  if (!job) return 'N/A';
  const s = job.clearing_status || job.forwarding_status;

  if (s) return capitalize(serviceData.job.clearing_status[s]);

  return 'N/A';
};

export const checkAgentOperatesOnThisPort = (agentPortsOfOperations, portId) => {
  const res = agentPortsOfOperations?.find(
    (p) => p?.document_verified === 1 && p?.port_uuid === portId,
  );
  // console.log('>>> agentPortsOfOperations, portId', agentPortsOfOperations, portId, res);

  return !!res;
};

export const getPortName = (portId, serviceData) =>
  serviceData?.ports?.find((p) => p?.uuid === portId)?.port_of_operation || 'N/A';
