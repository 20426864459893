import React, { useEffect, useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { InputFeedback } from '../UI/TextField';
import classNames from 'classnames';

const CountryCodePickerInput = ({ value, onChange, onBlur, error, className }) => {
  const [phone, setPhone] = useState();
  console.log(phone);
  const handleChange = (e) => {
    console.log(e);
    setPhone(e);
    onChange(e);
  };

  const InputClasses = classNames(
    'country-input',
    {
      'is-invalid': !!error,
    },
    className,
  );

  useEffect(() => {
    if (value) setPhone(value);
  }, [value]);

  return (
    <div>
      <PhoneInput
        placeholder="Enter Phone Number"
        value={phone}
        onChange={handleChange}
        onBlur={onBlur}
        className={InputClasses}
      />
      <InputFeedback error={error} />
    </div>
  );
};

export default CountryCodePickerInput;
