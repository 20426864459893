/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { showModal } from '../../redux/error/ErrorAction';
import LogoWhite from '../../../assets/img/logo-white.png';
import userImage from '../../../assets/img/placeHolderImage.png';
import ScrollToStart from '../UI/ScrollToStart';

const UnauthenticatUserHeader = (props) => {
  let history = useHistory();
  const wrapperRef = useRef(null);
  const [notice, setNotice] = useState(false);
  const [addHeaderClass, setHeaderClass] = useState(false);

  useEffect(() => {
    const calculateHeader = (e) => {
      if (window.scrollY >= 100) {
        setHeaderClass(true);
      } else {
        setHeaderClass(false);
      }
    };
    window.addEventListener('scroll', calculateHeader);

    return () => {
      window.removeEventListener('scroll', calculateHeader);
    };
  }, []);

  // console.log(user);
  const [unseenList] = useState([]);

  const toggleNotice = () => {
    setNotice(!notice);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current !== null) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setNotice(false);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const displayName = 'User is not logged in';

  return (
    <>
      <header id="header" className="header fixed-top dashboard-top">
        <div className="container-fluid ">
          <a
            className="logo d-flex align-items-center"
            onClick={() => {
              showModal('You are not logged in', false);
            }}
          >
            <img src={LogoWhite} alt="" width="233" />
          </a>

          <div className="loginright">
            <div className="notification">
              <a
                id="news"
                onClick={() => {
                  toggleNotice();
                  if (notice) {
                  }
                }}
              >
                <i className="fa fa-bell" aria-hidden="true" style={{ color: '#29bec1' }}></i>
              </a>
              <span>{unseenList && unseenList.length}</span>
            </div>
            <div className="loginleft">
              <a
                className="topprofile"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => setNotice(false)}
              >
                <img src={userImage} alt="" />

                <div className="copyright" style={{ color: 'white' }}>
                  {displayName}
                </div>
              </a>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a
                    onClick={() => {
                      history.push('/login');
                    }}
                    className="text-primary"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </div>

            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
      </header>
      {notice && (
        <div
          className="popover fade bottom in n-1"
          role="tooltip"
          id="popover430299"
          ref={wrapperRef}
        >
          <div className="arrow n-2"></div>
          <div className="popover-content">
            <div className="notification-block">
              <div className="row">
                <div className="col-sm-12 notification-mid">
                  <p>Your don't have a notification.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <ScrollToStart addHeaderClass={addHeaderClass} />
    </>
  );
};
export default UnauthenticatUserHeader;
