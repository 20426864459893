import { useFormik } from 'formik';
import { capitalize, startCase, camelCase } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';

const vlidationSchema = Yup.object({
  destination: Yup.string().when('category', (category, schema) => {
    if (category != 1) {
      return schema.required('Please enter destination');
    }
    return schema;
  }),
  category: Yup.number().required('Please select category'),
  date_of_arrival: Yup.date().required('Please select date of arrival'),
  type: Yup.string().when('category', (category, schema) => {
    if (category == 1) {
      return schema.required('Please select type');
    }
    return schema;
  }),
  single_item_title: Yup.string().when('category', (category, schema) => {
    console.log('>> category', category);
    if (category != 1) {
      return schema.required('Please enter item name');
    }
    return schema;
  }),
  port_of_discharge: Yup.string().required('Please select port of discharge'),
  email: Yup.string()
    .email('Please enter valid email')
    .required('Please enter email'),
  phone: Yup.string(),
});

const BothServiceTab = ({ onSubmit }) => {
  const serviceData = useSelector((state) => state.enumData);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      category: '1',
      date_of_arrival: '',
      no_of_containers: '',
      single_item_title: '',
      type: '1',
      port_of_discharge: '',
      email: '',
      phone: '',
      destination: '',
    },
    validationSchema: vlidationSchema,
    onSubmit: (values) => {
      if (values.category === '1') {
        values.tonnage = undefined;
        values.no_of_containers = undefined;
      }

      if (values.category === '2') {
        values.vehicle = undefined;
        values.tonnage = undefined;
      }

      if (values.category === '3') {
        values.no_of_containers = undefined;
        values.vehicle = undefined;
      }
      values.job_type = 'both';
      onSubmit(values, resetForm);
    },
  });

  return (
    <form onSubmit={handleSubmit} id="service-form">
      <Row>
        <Col md={6} lg={3} className="mt">
          <label style={{ marginBottom: '0px' }}>Select Category</label>
          <select
            name="category"
            className="form-control"
            onChange={(e) => {
              handleChange(e);
            }}
            value={values.category}
          >
            {Object.keys(serviceData.job.category).map((key) => (
              <option value={key} key={key}>
                {capitalize(serviceData.job.category[key])}
              </option>
            ))}
          </select>
          <span className="text-danger">{touched.category && errors.category}</span>
        </Col>

        {values.category === '1' && (
          <Col md={6} lg={3} className="mt">
            <label style={{ marginBottom: '0px' }}>Select Type</label>
            <select
              name="type"
              className="form-control"
              onChange={(e) => {
                handleChange(e);
              }}
              value={values.type}
            >
              {Object.keys(serviceData.job.vehicle_type).map((key) => (
                <>
                  {key !== '3' && (
                    <option value={key} key={key}>
                      {capitalize(serviceData.job.vehicle_type[key])}
                    </option>
                  )}
                </>
              ))}
            </select>

            <span className="text-danger">{touched.vehicle && errors.vehicle}</span>
          </Col>
        )}

        {(values.category === '2' || values.category === '3') && (
          <Col md={6} lg={3} className="mt">
            <label style={{ marginBottom: '0px' }}>Item Name {'(Separated by comma) '}</label>
            <input
              name="single_item_title"
              type="text"
              className="form-control"
              placeholder="item 1, item 2"
              onChange={handleChange}
              value={values.single_item_title}
            />
            <span className="text-danger">{touched.tonnage && errors.tonnage}</span>
          </Col>
        )}

        <Col md={6} lg={3} className="mt">
          <label style={{ marginBottom: '0px' }}>Port of Discharge</label>
          <select
            name="port_of_discharge"
            type="text"
            className="form-control"
            onChange={handleChange}
            value={values.port_of_discharge}
          >
            <option value="">Select Port</option>
            {serviceData?.ports?.length > 0 &&
              serviceData?.ports.map((obj) => (
                <option value={obj.uuid} key={obj.uuid}>
                  {startCase(camelCase(obj.port_of_operation))}
                </option>
              ))}
          </select>
          <span className="text-danger">
            {touched.port_of_discharge && errors.port_of_discharge}
          </span>
        </Col>

        <Col md={6} lg={3} className="mt">
          <label style={{ marginBottom: '0px' }}>Expected date of arrival</label>
          <input
            name="date_of_arrival"
            type="date"
            className="form-control"
            onChange={handleChange}
            value={values.date_of_arrival}
            min={new Date().toISOString().split('T')[0]}
          />
          <span className="text-danger">{touched.date_of_arrival && errors.date_of_arrival}</span>
        </Col>

        {values.category !== '1' && (
          <Col md={6} lg={3} className="mt">
            <label style={{ marginBottom: '0px' }} htmlFor="destination">
              Destination
            </label>
            <input
              name="destination"
              id="destination"
              type="text"
              className="form-control"
              placeholder="Enter your Destination"
              onChange={handleChange}
              value={values.destination}
            />
            <span className="text-danger"></span>
          </Col>
        )}
        <Col md={6} lg={values.category !== '1' ? 3 : 4} className="mt">
          <label style={{ marginBottom: '0px' }}>Email</label>
          <input
            name="email"
            type="email"
            className="form-control"
            placeholder="Enter your Email"
            onChange={handleChange}
            value={values.email}
          />
          <span className="text-danger">{touched.email && errors.email}</span>
        </Col>

        <Col md={6} lg={values.category !== '1' ? 3 : 4} className="mt">
          <label style={{ marginBottom: '0px' }}>Phone</label>
          <input
            name="phone"
            type="text"
            className="form-control"
            placeholder="Enter your Phone"
            onChange={handleChange}
            value={values.phone}
          />
        </Col>
        <Col md={6} lg={values.category !== '1' ? 3 : 4} className="mt">
          <div className="text-right">
            <button className="post-button w-100" type="submit" disabled={!(isValid && dirty)}>
              Submit
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default BothServiceTab;
