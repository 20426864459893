/* eslint-disable jsx-a11y/anchor-is-valid */
import { getIn, useFormik } from 'formik';
import React from 'react';
import { acceptedFileTypes } from '../../helpers/constants';
import { Button } from 'reactstrap';
import convertJsonToFormData from '../../helpers/helper';
import { POST } from '../../services/rest.service';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/error/ErrorAction';

const DocumentUploadForm = ({ job, onSuccess }) => {
  const dispatch = useDispatch();
  const [jobDetails, setJobDetails] = React.useState({});

  React.useEffect(() => {
    setJobDetails(job);
  }, [job]);

  const { setFieldValue, handleBlur, touched, errors, handleSubmit, values } = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      values.job_uuid = jobDetails.uuid;
      const formData = convertJsonToFormData(values);
      dispatch(showLoading());
      POST('/jobs/files/upload', formData)
        .then(({ data }) => {
          showModal('Documents uploaded successfully!', true);
          onSuccess(data);
        })
        .catch((err) => {
          showModal(err.message);
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    },
  });

  if (jobDetails.job_type_tags?.includes('clearing')) {
    if (jobDetails.lading_link && jobDetails.paar_link) {
      return <></>;
    }
  }

  if (jobDetails.job_type_tags?.includes('forwarding')) {
    if (jobDetails.performa_invoice) {
      return <></>;
    }
  }

  return (
    <>
      <hr />
      <form onSubmit={handleSubmit}>
        {jobDetails.job_type_tags?.includes('clearing') && (
          <>
            {!jobDetails.lading_link && (
              <div className="mt-3">
                <label style={{ marginBottom: '10px' }} htmlFor="lading_link">
                  Upload Bill of Lading
                </label>

                <input
                  type="file"
                  accept={acceptedFileTypes}
                  className={` ${
                    getIn(touched, 'lading_link') && getIn(errors, 'lading_link')
                      ? 'is-invalid'
                      : ''
                  }`}
                  placeholder="Upload Bill of Lading"
                  name="lading_link"
                  id="lading_link"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setFieldValue('lading_link', file);
                    }
                  }}
                  onBlur={handleBlur}
                />
                {getIn(touched, 'lading_link') && getIn(errors, 'lading_link') ? (
                  <div className="invalid-feedback mb-3">
                    {getIn(touched, 'lading_link') && getIn(errors, 'lading_link')}
                  </div>
                ) : null}
              </div>
            )}

            {!jobDetails.paar_link && (
              <div className="mt-3">
                <label style={{ marginBottom: '10px' }}>
                  Upload PAAR (pre-arrival assessment report)
                </label>

                <input
                  type="file"
                  accept={acceptedFileTypes}
                  className={` ${
                    getIn(touched, 'paar_link') && getIn(errors, 'paar_link') ? 'is-invalid' : ''
                  }`}
                  placeholder="Upload pre-arrival assessment report (PAAR)"
                  name="paar_link"
                  id="paar_link"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setFieldValue('paar_link', file);
                    }
                  }}
                  onBlur={handleBlur}
                />
                {getIn(touched, 'paar_link') && getIn(errors, 'paar_link') ? (
                  <div className="invalid-feedback mb-3">
                    {getIn(touched, 'paar_link') && getIn(errors, 'paar_link')}
                  </div>
                ) : null}
              </div>
            )}
          </>
        )}

        {jobDetails.job_type_tags?.includes('forwarding') && !jobDetails.performa_invoice && (
          <div className="mt-3">
            <label style={{ marginBottom: '10px' }}>Upload Proforma Invoice</label>

            <input
              type="file"
              accept={acceptedFileTypes}
              className={` ${
                getIn(touched, 'performa_invoice') && getIn(errors, 'performa_invoice')
                  ? 'is-invalid'
                  : ''
              }`}
              name="performa_invoice"
              id="performa_invoice"
              onChange={(e) => {
                const file = e.target.files[0];

                if (file) {
                  setFieldValue('performa_invoice', file);
                }
              }}
              onBlur={handleBlur}
            />
            {getIn(touched, 'performa_invoice') && getIn(errors, 'performa_invoice') ? (
              <div className="invalid-feedback mb-3">
                {getIn(touched, 'performa_invoice') && getIn(errors, 'performa_invoice')}
              </div>
            ) : null}
          </div>
        )}
        {(values.lading_link || values.paar_link || values.performa_invoice) && (
          <a className="approvebut mt-4" onClick={handleSubmit}>
            Upload Files
          </a>
        )}
      </form>
    </>
  );
};

export default DocumentUploadForm;
