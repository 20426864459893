import React, { useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { POST } from '../../../services/rest.service';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';

const sendMsgOtp = async (email) => {
  const res = await POST('/guest/send-email-otp', { email });
  return res;
};

const verifyMsgOtp = async (email, otp) => {
  const res = await POST('/guest/verify-email-otp', { email, otp });
  return res;
};

const EmailOptVerifyModal = ({ isOpen, toggle, email, container = 'home-page', onVerify }) => {
  const [isValid, setIsValid] = React.useState(false);
  const [otp, setOtp] = React.useState(['', '', '', '']);
  const [refs, setRefs] = React.useState([null, null, null, null]);

  const dispatch = useDispatch();

  const submit = async () => {
    dispatch(showLoading('Please wait...'));
    verifyMsgOtp(email, otp.join(''))
      .then((res) => {
        dispatch(hideLoading());
        dispatch(showModal(res.message, true));
        if (res.status == 'success') {
          onVerify();
        }
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  useEffect(() => {
    sendMsgOtp(email).then((res) => {
      dispatch(showModal(res.message, true));
      refs[0]?.focus();
    });
  }, []);

  const onOtpFieldChange = (val, index) => {
    if (val.length > 1) {
      val = val.slice(-1);
    }

    const newOtp = [...otp];
    newOtp[index] = val;
    setOtp(newOtp);

    if (refs[index + 1] && val) refs[index + 1].focus();
  };

  const onBackPress = (key, index) => {
    if (key !== 'Backspace') return;
    if (index === 0) return;

    if (refs[index - 1]) refs[index - 1].focus();
    const newOtp = [...otp];
    newOtp[index] = '';
    setOtp(newOtp);
  };

  React.useEffect(() => {
    if (otp[0] && otp[1] && otp[2] && otp[3]) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [otp]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} container={container} className="modal-xl">
      <div className="modal-header">
        <button type="button" className="close" onClick={toggle}>
          &times;
        </button>
      </div>
      <ModalBody>
        <div>
          <div className="text-center">
            <h2>Please Check Your Email</h2>
            <p>
              We have sent an OTP to <strong>{email}</strong>
            </p>
          </div>
          <div className="form-group d-flex justify-content-center my-5">
            <input
              type="text"
              className="form-control modal-otp-field text-center"
              onChange={(e) => {
                onOtpFieldChange(e.target.value, 0);
              }}
              value={otp[0]}
              ref={(ref) => (refs[0] = ref)}
              onKeyUp={(e) => onBackPress(e.key, 0)}
            />
            <input
              type="text"
              className="form-control modal-otp-field text-center"
              onChange={(e) => onOtpFieldChange(e.target.value, 1)}
              value={otp[1]}
              ref={(ref) => (refs[1] = ref)}
              onKeyUp={(e) => onBackPress(e.key, 1)}
            />
            <input
              type="text"
              className="form-control modal-otp-field text-center"
              onChange={(e) => onOtpFieldChange(e.target.value, 2)}
              value={otp[2]}
              ref={(ref) => (refs[2] = ref)}
              onKeyUp={(e) => onBackPress(e.key, 2)}
            />
            <input
              type="text"
              className="form-control modal-otp-field text-center"
              onChange={(e) => onOtpFieldChange(e.target.value, 3)}
              value={otp[3]}
              ref={(ref) => (refs[3] = ref)}
              onKeyUp={(e) => onBackPress(e.key, 3)}
            />
          </div>
          <div className="text-center">
            <button
              className="post-button btn-modal-submit w-100"
              disabled={!isValid}
              onClick={submit}
            >
              Verify
            </button>
            <p className="mt-1">
              Didn't receive the OTP?{' '}
              <a
                href="#!"
                onClick={(e) => {
                  e.preventDefault();
                  sendMsgOtp(email).then((res) => {
                    if (res.status === 'success') {
                      dispatch(showModal(res.message, true));
                    }
                  });
                }}
              >
                Resend OTP
              </a>
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EmailOptVerifyModal;
