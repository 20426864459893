import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import UpdatePassword from '../components/global/UpdatePassword';
import UserHeader from '../components/global/UserHeader';
import AppLoader from '../components/loaders/AppLoader';
import Dashboard from '../containers/Client/Dashboard';
import DisputesDetails from '../components/global/DisputesDetails';
import DisputesList from '../components/global/DisputesList';
import EditProfile from '../containers/Client/EditProfile';
import ClientJobs from '../containers/Client/Jobs/ClientJobs';
// import MessageList from '../containers/Client/Messages/MessageList';
import PaymentFirstStep from '../containers/Client/Payment/PaymentFirstStep';
import MyReceipts from '../containers/Client/Payment/MyReceipts';
import ClientJobDetails from '../containers/Client/Jobs/ClientJobDetails';
import ClientRejectedJobDetails from '../containers/Client/Jobs/RejectedJobDetails';
// import ClientVerificationModal from '../components/Modals/ClientVerificationModal';
import Documents from '../components/global/ShowDocument';
import UpdateDocuments from '../components/global/UpdateDocuments';
import Notification from '../components/global/Notification';
import NotificationMenuItem from '../components/global/NotificationBadge';
import { NT_C_MY_JOBS, NT_C_DISPUTES, NT_C_PAYMENTS } from '../helpers/constants';
import SuspensionModal from '../components/Modals/SuspensionModal';
import { hideSuspensionModal, hideDocumentModal } from '../redux/user/UserActions';
import DocumentModal from '../components/Modals/DocumentModal';
import ReceiptDetails from '../containers/Client/Payment/ReceiptDetails';
import { readNotification } from '../redux/notification/NotificationActions';
import CreateServices from '../containers/Client/CreateServices';
import ClientRegSuccessModal from '../components/Modals/ClientRegSuccessModal';
import ErrorBoundary from '../components/global/ErrorBoundary';

export const ValidateRoutes = ({ component: Component, has_permission, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        has_permission && has_permission !== undefined ? (
          <ErrorBoundary key={props.location.pathname}>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const ClientRoute = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userStoreState = useSelector((state) => state.user);
  const { user } = userStoreState;
  const [verficationModal, setVerificationModal] = useState(false);

  const [siderMemuToggle, setSiderMemuToggle] = useState(false);

  const toggleVerificationModal = () => setVerificationModal((s) => !s);

  // useEffect(() => {
  //   console.log('user', user);
  //   if (!isEmpty(user)) {
  //     toggleVerificationModal();
  //   }
  // }, [user]);

  const { match } = props;
  const { pathname } = props.location;

  if (isEmpty(user)) {
    return <AppLoader />;
  }

  const onMenuClick = (path, notificationTypes) => {
    history.push(path);
    setSiderMemuToggle(false);

    // mark the notifications of the menu as read
    if (notificationTypes) {
      dispatch(readNotification({ notificationTypes }));
    }
  };

  return (
    <div id="client-page">
      <Helmet>
        <title>GoAgent</title>
      </Helmet>
      <UserHeader />
      <main id="main" className="main-block">
        <div className="bodycont">
          <div style={{ clear: 'both' }}></div>

          <div className="tab">
            <div className="cormen-btn">
              <label htmlFor="cormid"></label>
            </div>
            <div className="cormen">
              <input
                id="cormid"
                type="checkbox"
                checked={siderMemuToggle}
                onChange={(e) => setSiderMemuToggle(e.target.checked)}
              />
              <ul>
                {user.identity_verified === 0 || user.identity_verified === 2 ? (
                  <button
                    className={`tablinks ${pathname.includes('/client/jobs') ? 'active' : ''}`}
                    onClick={() => {
                      onMenuClick('/client/jobs');
                      toggleVerificationModal();
                    }}
                  >
                    <NotificationMenuItem title="My Jobs" types={NT_C_MY_JOBS} />
                  </button>
                ) : (
                  <button
                    className={`tablinks ${pathname.includes('/client/jobs') ? 'active' : ''}`}
                    onClick={() => {
                      onMenuClick('/client/jobs', NT_C_MY_JOBS);
                    }}
                  >
                    <NotificationMenuItem title="My Jobs" types={NT_C_MY_JOBS} />
                  </button>
                )}
                <button
                  className={`tablinks ${
                    pathname.includes('/client/profile') ||
                    pathname.includes('/client/edit-profile')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => onMenuClick('/client/profile')}
                >
                  Profile
                </button>
                <button
                  className={`tablinks ${pathname.includes('/receipts') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/client/receipts', NT_C_PAYMENTS)}
                >
                  <NotificationMenuItem title="Transaction History" types={NT_C_PAYMENTS} />
                </button>
                <button
                  className={`tablinks ${pathname.includes('/dispute') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/client/disputes', NT_C_DISPUTES)}
                >
                  <NotificationMenuItem title="Claims" types={NT_C_DISPUTES} />
                </button>
                {/* <button className={`tablinks ${pathname.includes('/messages') ? 'active' : ''}`} onClick={() => onMenuClick('/client/messages')}>
                  Message
                </button> */}
                <button
                  className={`tablinks ${pathname.includes('/notifications') ? 'active' : ''}`}
                  onClick={() => onMenuClick('/client/notifications')}
                >
                  Messages
                </button>
              </ul>
            </div>
          </div>

          <Switch>
            <ValidateRoutes
              exact
              path={`${match.url}/profile`}
              component={Dashboard}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/profile/update-password`}
              component={UpdatePassword}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/edit-profile`}
              component={EditProfile}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/jobs`}
              component={ClientJobs}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/job/create-service-request`}
              component={CreateServices}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/payments`}
              component={PaymentFirstStep}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/receipts`}
              component={MyReceipts}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/receipt/:receiptId/details`}
              component={ReceiptDetails}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/disputes`}
              component={DisputesList}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/job/details`}
              component={ClientJobDetails}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/job/service/details`}
              component={ClientRejectedJobDetails}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />

            <ValidateRoutes
              exact
              path={`${match.url}/dispute/details`}
              component={DisputesDetails}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/documents`}
              component={Documents}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            <ValidateRoutes
              exact
              path={`${match.url}/update-documents`}
              component={UpdateDocuments}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />
            {/* <ValidateRoutes
              exact
              path={`${match.url}/messages`}
              component={MessageList}
              has_permission={get(user, 'type') === 'application_client' || get(user, 'type') === 'application_corporate_client'}
            /> */}
            <ValidateRoutes
              exact
              path={`${match.url}/notifications`}
              component={Notification}
              has_permission={
                get(user, 'type') === 'application_client' ||
                get(user, 'type') === 'application_corporate_client'
              }
            />

            <Redirect from={match.url} to={`${match.url}/jobs`} />
          </Switch>
          <div className="clearfix"></div>
        </div>

        {/* <ClientVerificationModal
          verficationModal={verficationModal}
          toggleVerificationModal={toggleVerificationModal}
          container="client-page"
        /> */}
        <ClientRegSuccessModal
          isOpen={verficationModal}
          toggle={toggleVerificationModal}
          container="client-page"
        />
        {/* Modal to Display Suspension */}
        {userStoreState?.displaySuspensionModal && (
          <SuspensionModal
            container="client-page"
            isOpen={userStoreState.displaySuspensionModal}
            toggle={() => dispatch(hideSuspensionModal())}
          />
        )}
        {/* Modal to Display Document */}
        {userStoreState?.displayDocumentModal && (
          <DocumentModal
            isOpen={userStoreState.displayDocumentModal}
            toggle={() => dispatch(hideDocumentModal())}
            documentToShow={userStoreState.documentToDisplay}
            container="client-page"
          />
        )}
      </main>
    </div>
  );
};

export default ClientRoute;
