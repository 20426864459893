import React, { useCallback, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import './serviceCard.css';
import ClearingServiceTab from './ClearingServiceTab';
import TransportServiceTab from './TransportServiceTab';
import BothServiceTab from './BothServiceTab';
import { POST } from '../../../services/rest.service';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../redux/error/ErrorAction';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import configObject from '../../../../config';
import EmailOptVerifyModal from './EmailOptVerifyModal';

const ServiceCard = (props) => {
  const [activeTab, setActiveTab] = React.useState('1');
  const [token, setToken] = useState(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);
  const [recToken, setRecToken] = useState();
  const [values, setValues] = useState({});
  const [resetForm, setResetForm] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const recTokenResult = await executeRecaptcha('contactForm');
    setRecToken(recTokenResult);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  useEffect(() => {
    const captchas = document.getElementsByClassName('grecaptcha-badge');
    for (let i = 0; i < captchas.length; i++) {
      captchas.item(i).style.visibility = 'visible';
    }

    return () => {
      for (let i = 0; i < captchas.length; i++) {
        captchas.item(i).style.visibility = 'hidden';
      }
    };
  }, []);

  const onSubmit = async (values, resetForm, onError) => {
    setResetForm(() => resetForm);
    await handleReCaptchaVerify();
    setValues(values);
    setIsModalOpen(true);
  };

  const onOtpVerified = () => {
    setIsModalOpen(false);
    dispatch(showLoading('Please wait...'));
    POST('/guest/job-post', values)
      .then(({ data }) => {
        dispatch(hideLoading());
        dispatch(showModal('Job posted successfully and details sent to email', true));
        resetForm();
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  const refreshToken = () => {
    setRefreshReCaptcha((r) => !r);
  };

  const handleVerify = (newToken) => {
    setToken(newToken);
  };

  return (
    <div
      className="service-tab overflow-hidden p-0"
      style={{ borderRadius: '10px', background: 'rgb(255 255 255 / 80%)' }}
    >
      <div className="d-flex shadow-sm">
        <Nav
          pills
          style={{ color: '#111', background: '#01a8af', display: 'flex' }}
          className="catagory-form"
        >
          <NavItem style={{ display: 'flex', flex: '1' }}>
            <NavLink
              style={{ color: '#ffff', width: '100%' }}
              active={activeTab === '1'}
              href="#"
              onClick={() => setActiveTab('1')}
            >
              Clearing
            </NavLink>
          </NavItem>
          <NavItem style={{ display: 'flex', flex: '1' }}>
            <NavLink
              style={{ color: '#fff', width: '100%' }}
              active={activeTab === '2'}
              href="#"
              onClick={() => setActiveTab('2')}
            >
              Transport
            </NavLink>
          </NavItem>
          <NavItem style={{ display: 'flex', flex: '1' }}>
            <NavLink
              style={{ color: '#fff', width: '100%' }}
              active={activeTab === '3'}
              href="#"
              onClick={() => setActiveTab('3')}
            >
              Both
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <div className="px-5 pt-3 pb-5">
        {activeTab === '1' && <ClearingServiceTab onSubmit={onSubmit} />}
        {activeTab === '2' && <TransportServiceTab onSubmit={onSubmit} />}
        {activeTab === '3' && <BothServiceTab onSubmit={onSubmit} />}
      </div>
      {isModalOpen && (
        <EmailOptVerifyModal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          email={values.email}
          onVerify={onOtpVerified}
        />
      )}
    </div>
  );
};

export default ServiceCard;
