import axios from 'axios';
import config from '../../config';
import { setUserActivity } from './refreshToken';

const instance = axios.create();

instance.defaults.baseURL = config.api.URL;

/**
 * Global request function for handling all HTTP calls
 * @param method
 * @param url
 * @param options {params: <for query params>, payload: <for data to be sent>'}
 */

const request = (method, url, options = { params: {}, payload: {} }) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
  };

  return new Promise((resolve, reject) => {
    instance
      .request(request)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const POST = (path, payload) => {
  return request('POST', path, { payload });
};

export const GET = (path, params) => {
  return request('GET', path, { params });
};

export const GETALL = (path, filters) => {
  return request('GET', path, { params: filters });
};

export const PUT = (path, payload) => {
  return request('PUT', path, { payload: payload });
};

export const PATCH = (path, payload) => {
  return request('PATCH', path, { payload: payload });
};

export const DELETE = (path) => {
  return request('DELETE', path);
};

instance.interceptors.request.use((request) => {
  // Setting up headers
  const token = sessionStorage.getItem('_GATKEN');
  let headers = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
  };
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }

  request.headers = headers;

  // every time API is called mark the activity
  // used for checking inactivity
  if (request.url !== '/auth/refreshToken') {
    setUserActivity();
  }

  return request;
});

/**
 * RESPONSE INTERCEPTOR
 */
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response.data;
  },
  (error) => {
    if (error?.code === 401) {
      sessionStorage.removeItem('_GATKEN');
      window.location.href = '/';
    } else if (error?.response?.status === 429) {
      return Promise.reject({
        message: error.response.statusText,
        code: error.response.status,
        status: 'error',
      });
    }
    return Promise.reject(error?.response?.data || 'Something went wrong!');
  },
);
