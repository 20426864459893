import React, { useEffect, useState } from 'react';
import { capitalize, isEmpty, startCase, camelCase } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import docImage from '../../../assets/img/doc.png';
import AppLoader from '../../components/loaders/AppLoader';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { GET, POST, DELETE } from '../../services/rest.service';
import { showModal } from '../../redux/error/ErrorAction';
import {
  weightFormat,
  accessGeneralOrCargoType,
  getJobStatus,
  getPortName,
} from '../../helpers/utils';
import { LeftArrowSvg } from '../../components/UI/CommanUI';
import Chat from '../../components/UI/Chat';
import { showDocumentModal } from '../../redux/user/UserActions';
import RejectModal from '../../components/Modals/RejectModal';
import { useChat } from '../../helpers/customHooks';
import JobUnbundleModal from './JobUnbundleModal';
import ExtendPaymentTimeModal from './ExtendPaymentTimeModal';
import JobApproveModal from './JobApproveModal';
import AssignAgentToJobModal from './AssignAgentToJobModal';
import DocumentUploadForm from './DocumentUploadForm';

const NewJobDetail = (props) => {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.enumData);

  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [unBundleModal, setUnBundleModall] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [unreadCount, setUnreadCount] = useState(0);
  const [extendTimeModal, setExtendTimeModal] = useState(false);
  const [assignAgentModal, setAssignAgentModal] = useState(false);

  const [jobStatus, setJobStatus] = useState('');
  const [jobClearingPort, setClearingPort] = useState('');
  const [pickupPort, setPickupPort] = useState('');

  const toggleApproveModal = () => setApproveModal((s) => !s);
  const toggleUnBundelModal = () => setUnBundleModall((s) => !s);
  const toggleRejectModal = () => setRejectModal((s) => !s);
  const toggleExtendTimeModal = () => setExtendTimeModal((s) => !s);
  const toggleAssignAgentModal = () => setAssignAgentModal((s) => !s);

  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  useEffect(() => {
    getJobDetails();
  }, []);

  useEffect(() => {
    setJobStatus(getJobStatus(jobDetails, serviceData));
    setClearingPort(
      startCase(camelCase(getPortName(jobDetails?.clearing_discharge_port, serviceData))),
    );
    setPickupPort(startCase(camelCase(getPortName(jobDetails?.port_of_discharge, serviceData))));
  }, [jobDetails?.port_of_discharg, jobDetails?.clearing_discharge_port, serviceData?.ports]);

  const getJobDetails = () => {
    const id = props.match.params.id;
    dispatch(showLoading());
    GET(`/jobs/getDetails/${id}`)
      .then(({ data }) => {
        // console.log('data', data);
        setJobDetails(data.job);
        dispatch(hideLoading());
      })
      .catch((err) => {
        // console.log('err', err);
        dispatch(showModal(err.message));
        dispatch(hideLoading());
        props.history.goBack();
      });
  };

  // CHAT Related code -- starts here
  const { user } = useSelector((state) => state.user);
  const job_id = props.match.params.id;
  const userIsWorkSupervisor = user.type === 'work_supervisor';

  const [chatbox, setChatbox] = useState(false);
  const [chatlist, setChatlist] = useState([]);
  const [msg, setMsg] = useState('');

  const chatName = user.type === 'application_client' ? 'Us' : 'Client';
  const chatBlockId = chatName === 'Client' ? 'chat-tops' : 'chat-top';

  const showUpdateJob =
    jobDetails.clearing_status === '6' ||
    jobDetails.forwarding_status === '6' ||
    jobDetails.clearing_status === '3' ||
    jobDetails.forwarding_status === '3' ||
    jobDetails.clearing_status === '11' ||
    jobDetails.clearing_status === '12';

  // initialize and subcsribe to chat here
  const handleChatEvent = (data1) => {
    if (data1.chat_type === 'client') {
      if (!chatbox) {
        // set unread count when chat is not openend
        setUnreadCount((prevState) => prevState + 1);
      }

      setChatlist((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById(chatBlockId);
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }
    dispatch(showModal('you have a new message', true));
  };
  useChat(user.uuid, chatbox, handleChatEvent);

  //chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${job_id}`)
      .then(({ data }) => {
        // console.log(data);
        if (user.type === 'application_client') {
          const oc = data.chats.operator?.length > 0 ? data.chats.operator.reverse() : [];
          setChatlist(oc);
        } else {
          const cc = data.chats.client?.length > 0 ? data.chats.client.reverse() : [];
          setChatlist(cc);
        }
        const objDiv = document.getElementById(chatBlockId);
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };

  const chatToggle = () => {
    if (!chatbox) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount(0);
    }
    setChatbox(!chatbox);
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }

    const payLoad = {
      job_uuid: job_id,
      sender_uuid: user.uuid,
      message: message,
      chat_type: 'client',
    };
    if (user.type === 'application_client') {
      payLoad.receiver_uuid = jobDetails.operator_uuid;
    } else if (user.type === 'managenmnt_operator') {
      payLoad.receiver_uuid = jobDetails.created_by;
    }

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        setChatlist((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById(chatBlockId);
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        // getChat();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };
  // CHAT Related code -- ends here

  const handleJobReject = (values) => {
    dispatch(showLoading());
    POST(`/jobs/operatorRejectJob`, {
      job_uuid: jobDetails.uuid,
      comment: values.comment,
    })
      .then(({ data }) => {
        // console.log('data', data);
        let jobData = { ...jobDetails, clearing_status: data.job.clearing_status };
        setJobDetails(jobData);
        dispatch(hideLoading());
        dispatch(showModal(`Service rejected successfully!`, true));
        props.history.goBack();
      })
      .catch((err) => {
        dispatch(hideLoading());
        dispatch(showModal(err.message));
      });
  };

  const handleDeleteJob = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Delete this job?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        const id = props.match.params.id;
        dispatch(showLoading());
        DELETE(`/jobs/${id}`)
          .then(() => {
            dispatch(showModal('Job Deleted Sucessfully!', true));
            dispatch(hideLoading());
            props.history.goBack();
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
          });
      }
    });
  };

  const onJobUnbundle = () => {
    getJobDetails();
    toggleUnBundelModal();
  };

  const onExtendJobTime = () => {
    getJobDetails();
    toggleExtendTimeModal();
  };

  const onJobApprove = (data) => {
    setJobDetails(data);
    toggleApproveModal();
    props.history.goBack();
  };

  const onAssignAgentToJob = () => {
    getJobDetails();
    toggleAssignAgentModal();
  };

  const checkReadyToSubmit = () => {
    if (jobDetails.client.email_verified_at == null) {
      return false;
    }
    if (jobDetails.paar_link == null) {
      return false;
    }
    if (jobDetails.lading_link == null) {
      return false;
    }
    if (jobDetails.performa_invoice == null) {
      if (jobDetails.job_type_tags?.includes('forwarding')) {
        return false;
      }
    }
    return true;
  };

  const canApprove = () => {
    if (
      jobDetails.has_forwarding === 1 &&
      jobDetails.tonnage &&
      jobDetails.children_packages.length <= 0
    ) {
      return true;
    }

    if (
      jobDetails.no_of_containers === null &&
      jobDetails.no_of_containers <= 0 &&
      !jobDetails.tonnage
    ) {
      return true;
    }

    if (jobDetails.children_packages.length > 0) {
      return true;
    }

    if (jobDetails.has_forwarding === 0) {
      return true;
    }

    return false;
  };

  if (!jobDetails || isEmpty(serviceData)) {
    return <AppLoader />;
  }
  return (
    <>
      <div id="tabblock1" className="tabcontent">
        <div className="bodycont">
          <div>
            <h2>
              <span
                onClick={() => {
                  props.history.goBack();
                }}
                style={{ cursor: 'pointer', float: 'left' }}
              >
                <LeftArrowSvg />
              </span>
              Job Details
            </h2>
          </div>

          <div className="servicetab">
            <div className="operator-info">
              <div>
                {jobDetails.client && jobDetails.client.email_verified_at == null && (
                  <div className="row">
                    <div className="col-lg-10 col-sm-12">
                      <h3 className="text-danger">Email is not verifyed yet</h3>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-lg-10 col-sm-12">
                    <h5> {capitalize(serviceData.job.category[jobDetails.category]) || 'N/A'}</h5>
                    <h6>{jobDetails.job_id || 'N/A'}</h6>
                  </div>
                  <div className="col-lg-2 col-sm-12 text-right-lg">
                    <h4>
                      {jobDetails.job_type_tags &&
                        jobDetails.job_type_tags.map((y, i) => (
                          <p key={i}>{capitalize(y === 'forwarding' ? 'Transport' : y)} Service</p>
                        ))}
                    </h4>
                  </div>
                </div>

                <hr />
                <h6>Job Details</h6>
                {jobDetails.operator_comment && (
                  <p className="alert alert-danger">
                    Reason for Rejection: <strong>{jobDetails.operator_comment}</strong>
                  </p>
                )}
                <p>
                  Status: <strong>{getJobStatus(jobDetails, serviceData)}</strong>
                </p>

                {jobDetails?.clearing_discharge_port && (
                  <p>Clearing Port of Discharge: {jobClearingPort}</p>
                )}
                {jobDetails?.port_of_discharge && <p>Pick-up Location: {pickupPort}</p>}

                {jobDetails.category === 1 && (
                  <div>
                    <p>
                      Type of vehicle:{' '}
                      {capitalize(serviceData.job.vehicle_type[jobDetails.type]) || 'N/A'}{' '}
                    </p>
                    {jobDetails.type !== 1 && (
                      <p>No of containers : {jobDetails.no_of_containers || 'N/A'} </p>
                    )}
                    <p>
                      Expected date of arrival :{' '}
                      {moment(jobDetails.date_of_arrival).format('DD/MM/YYYY') || 'N/A'}{' '}
                    </p>
                    {jobDetails.containers.map((x, index) => (
                      <div className="row" key={index}>
                        <hr />
                        <h6>Container {index + 1}</h6>
                        <p>Description of load : {x.load_description || 'N/A'}</p>
                        {x.vehicles.map((v, i) => (
                          <div className="col-lg-4 col-sm-12" key={i}>
                            <div className="alert alert-info">
                              {jobDetails.type === 1 ? (
                                <h6>Vehicle Details</h6>
                              ) : (
                                <h6>Vehicle {i + 1}</h6>
                              )}
                              <ul>
                                <li>Brand: {v.brand}</li>
                                <li>Year: {v.year}</li>
                                <li>Model: {v.model}</li>
                                <li>
                                  Conditions:{' '}
                                  {capitalize(serviceData.job.vehicle_state[v.vehicle_state])}
                                </li>
                                <li>VREG Number : {v.vreg_no || 'N/A'}</li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                    {jobDetails?.vehicles?.length > 0 && (
                      <>
                        <hr />
                        <div className="row mx-0">
                          {jobDetails.vehicles.map((x, index) => (
                            <div className="col-lg-4 col-sm-12" key={index}>
                              <div className="alert alert-info">
                                {jobDetails.type === 1 ? (
                                  <h6>Vehicle Details</h6>
                                ) : (
                                  <h6>Vehicle {index + 1}</h6>
                                )}
                                <p>Brand: {x.brand}</p>
                                <p>Year: {x.year}</p>
                                <p>Model: {x.model}</p>
                                <p>
                                  Conditions:{' '}
                                  {capitalize(serviceData.job.vehicle_state[x.vehicle_state])}
                                </p>
                                <p>VREG Number : {x.vreg_no || 'N/A'}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {jobDetails.category === 2 && (
                  <div>
                    <p>No of containers : {jobDetails.no_of_containers || 'N/A'} </p>
                    <p>Type of cargo : {accessGeneralOrCargoType(jobDetails, serviceData)} </p>
                    {jobDetails.type_of_cargo === 1 && (
                      <p>Item Name : {jobDetails.single_item_title || 'N/A'} </p>
                    )}
                  </div>
                )}
                {jobDetails.category === 3 && (
                  <div>
                    <p>Tonnage : {jobDetails.tonnage || 'N/A'} </p>
                    <p>Type of cargo : {accessGeneralOrCargoType(jobDetails, serviceData)} </p>
                    <p>Item Name : {jobDetails.single_item_title || 'N/A'} </p>
                  </div>
                )}
                {(jobDetails.paar_link ||
                  jobDetails.lading_link ||
                  jobDetails.performa_invoice ||
                  jobDetails.marine_inspection_report) && (
                  <div className="row mx-0">
                    <hr />
                    {jobDetails.paar_link && (
                      <div className="col-lg-4 col-sm-12">
                        <h6>PAAR File</h6>
                        <span onClick={() => showDoc(jobDetails.paar_link)}>
                          <img src={docImage} title="GoAgent" alt="" />
                        </span>
                      </div>
                    )}
                    {jobDetails.lading_link && (
                      <div className="col-lg-4 col-sm-12">
                        <h6>Bill of Lading File</h6>
                        <span onClick={() => showDoc(jobDetails.lading_link)}>
                          <img src={docImage} title="GoAgent" alt="" />
                        </span>
                      </div>
                    )}
                    {jobDetails.performa_invoice && (
                      <div className="col-lg-4 col-sm-12">
                        <h6>Proforma Invoice</h6>
                        <span onClick={() => showDoc(jobDetails.performa_invoice)}>
                          <img src={docImage} title="GoAgent" alt="" />
                        </span>
                      </div>
                    )}
                    {jobDetails.marine_inspection_report && (
                      <div className="col-lg-4 col-sm-12">
                        <h6>Marine Inspection Report</h6>
                        <span onClick={() => showDoc(jobDetails.marine_inspection_report)}>
                          <img src={docImage} title="GoAgent" alt="" />
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {jobDetails.has_forwarding === 1 && (
                  <div>
                    <hr />
                    <h6>Transport Service Details</h6>

                    <p>
                      Port of discharge :{' '}
                      {startCase(
                        camelCase(serviceData.port_locations[jobDetails.port_of_discharge]),
                      ) || 'N/A'}{' '}
                    </p>
                    <p>Destination : {jobDetails.destination || 'N/A'} </p>
                    <p>
                      State:{' '}
                      {serviceData.states.find((s) => s.uuid === jobDetails.destination_state)
                        ?.state || 'N/A'}{' '}
                    </p>
                    {/* <p>
                      Value of Goods:{' '}
                      {jobDetails.goods_value ? displayAmount(jobDetails.goods_value) : 'N/A'}
                    </p>
                    <p>
                      Nature of Goods:{' '}
                      {jobDetails.goods_nature
                        ? `${serviceData.job.nature_of_goods[jobDetails.goods_nature]} Risk`
                        : 'N/A'}
                    </p> */}
                  </div>
                )}
                {jobDetails.children_packages && jobDetails.children_packages.length ? (
                  <div>
                    {jobDetails.children_packages.map((x, index) => (
                      <div key={index}>
                        <hr />
                        {index === 0 && <h6>Unbundled Jobs</h6>}
                        <h6>
                          Job {index + 1} : {x.job_id}
                        </h6>

                        <p>Size: {capitalize(serviceData.truck.size[x.size])}</p>
                        <p>Weight: {weightFormat(x.weight)}</p>
                        <div className="alert alert-info">
                          <h4>Truck Requirement</h4>
                          <ul>
                            <li>size: {x.truck.size}</li>
                            {/* <li>axel: {x.truck.axel}</li> */}
                            {jobDetails.category !== 3 && (
                              <li>hooks: {jobDetails?.no_of_hooks || 'N/A'}</li>
                            )}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}

                {/* document upload form start*/}
                <DocumentUploadForm
                  job={jobDetails}
                  onSuccess={() => {
                    getJobDetails();
                  }}
                />
                {/* document upload form end */}

                {/* for Unbundle and Approval of job */}
                {!userIsWorkSupervisor &&
                  jobDetails.children_packages &&
                  (jobDetails.clearing_status === '6' || jobDetails.clearing_status === '11') && (
                    <div
                      className="buttonsec1 text-center-sm"
                      style={{ paddingTop: '30px', paddingBottom: 0 }}
                    >
                      <a className="rejectbut" onClick={toggleRejectModal}>
                        Reject Job
                      </a>
                      {jobDetails.has_forwarding === 1 &&
                        jobDetails.no_of_containers >= 1 &&
                        jobDetails.children_packages.length <= 0 && (
                          <a className="approvebut" onClick={toggleUnBundelModal}>
                            Unbundle Job
                          </a>
                        )}
                      {/* {jobDetails.has_forwarding === 1 &&
                        jobDetails.tonnage &&
                        jobDetails.children_packages.length <= 0 && (
                          <a className="approvebut" onClick={toggleUnBundelModal}>
                            Unbundle Job
                          </a>
                        )} */}
                      {showUpdateJob && (
                        <button
                          className="update-job"
                          onClick={() => {
                            props.history.push('/operator/job/update-service-request', {
                              ...jobDetails,
                            });
                          }}
                        >
                          Update Job
                        </button>
                      )}
                      {canApprove() && (
                        <a className="approvebut" onClick={toggleApproveModal}>
                          Approve
                        </a>
                      )}
                    </div>
                  )}

                {/* For Extending Payment pending duration, after accepting bid or delete job*/}
                {(jobDetails.clearing_status === '9' || jobDetails.forwarding_status === '9') && (
                  <div
                    className="buttonsec1 text-center-sm"
                    style={{ paddingTop: '30px', paddingBottom: 0 }}
                  >
                    <a className="approvebut red-bg" onClick={handleDeleteJob}>
                      Delete Job <i className="fa fa-trash"></i>
                    </a>
                    {jobDetails.payment_extension &&
                      new Date(jobDetails.payment_extension).getTime() < new Date().getTime() && (
                        <a className="approvebut" onClick={toggleExtendTimeModal}>
                          Extend Time
                        </a>
                      )}
                  </div>
                )}
                {/* for Assigning agent for job for which bidding time is over and no bid is made */}
                {(jobDetails.clearing_status === '10' || jobDetails.forwarding_status === '10') && (
                  <div
                    className="buttonsec1 text-center-sm"
                    style={{ paddingTop: '30px', paddingBottom: 0 }}
                  >
                    <a className="approvebut" onClick={toggleAssignAgentModal}>
                      Assign Agent
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="buttonsec" style={{ textAlign: 'center' }}>
              <a onClick={() => props.history.goBack()} className="rejectbut">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>

      <JobUnbundleModal
        isOpen={unBundleModal}
        toggle={toggleUnBundelModal}
        jobDetails={jobDetails}
        onUnbundle={onJobUnbundle}
      />

      {approveModal && (
        <JobApproveModal
          isOpen={approveModal}
          toggle={toggleApproveModal}
          jobDetails={jobDetails}
          onApprove={onJobApprove}
        />
      )}

      {/* Reject Modal */}
      <RejectModal
        onSubmit={handleJobReject}
        isOpen={rejectModal}
        toggle={toggleRejectModal}
        container="client-page"
      />
      {/* only show chat if it is edited job and dont display for supervisor */}
      {!userIsWorkSupervisor && jobDetails.operator_comment && (
        <Chat
          name={chatName}
          chatbox={chatbox}
          user={user}
          chatToggle={chatToggle}
          chatlist={chatlist?.length > 0 ? chatlist : []}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
          unreadMsgsCount={unreadCount}
        />
      )}
      <ExtendPaymentTimeModal
        isOpen={extendTimeModal}
        toggle={toggleExtendTimeModal}
        onExtend={onExtendJobTime}
        jobDetails={jobDetails}
      />

      {assignAgentModal && (
        <AssignAgentToJobModal
          isOpen={assignAgentModal}
          toggle={toggleAssignAgentModal}
          jobDetails={jobDetails}
          onAssign={onAssignAgentToJob}
        />
      )}
    </>
  );
};

export default NewJobDetail;
