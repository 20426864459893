import axios from 'axios';
import CryptoJS from 'crypto-js';
import configs from '../../config';
import { getName } from '../helpers/utils';
import {
  SERVICETYPE_CLEARING,
  SERVICETYPE_FORWARDING,
  SERVICETYPE_SUSPENSION,
  SERVICETYPE_SUBSCRIPTION,
  CURRENCY_USD,
} from '../helpers/constants';

const serviceMapping = {
  [SERVICETYPE_CLEARING]: configs.REMITA_CLEARING_SERVICETYPE_ID,
  [SERVICETYPE_FORWARDING]: configs.REMITA_FORWARDING_SERVICETYPE_ID,
  [SERVICETYPE_SUSPENSION]: configs.REMITA_PENALTY_SERVICETYPE_ID,
  [SERVICETYPE_SUBSCRIPTION]: configs.REMITA_SUBSCRIPTION_SERVICETYPE_ID,
};

const instance = axios.create();
instance.defaults.baseURL = configs.REMITA_GEN_RRR_URL;

const generateRRR = (data) => {
  // console.log('>> INSIDE generateRRR data', data);
  const orderId = `${data.orderId}-${Date.now()}`;

  let serviceTypeId = '';
  if (data.currency === CURRENCY_USD) {
    if (data.serviceType === SERVICETYPE_CLEARING) {
      serviceTypeId = configs.REMITA_CLEARING_SERVICETYPE_ID_DOLLAR;
    } else if (data.serviceType === SERVICETYPE_FORWARDING) {
      serviceTypeId = configs.REMITA_SUBSCRIPTION_SERVICETYPE_ID_DOLLAR;
    }
  } else {
    serviceTypeId = serviceMapping[data.serviceType];
    // console.log('>> INSIDE generateRRR  serviceTypeId', serviceTypeId);
  }

  const payload = {
    serviceTypeId: serviceTypeId,
    amount: data.amount,
    orderId: orderId,
    payerName: getName(data),
    payerEmail: data.email,
    payerPhone: data.mobile_number,
    description: `Payment for ${data.serviceType} - ${data.orderId}`,
  };

  const apiHash = CryptoJS.SHA512(
    configs.REMITA_MERCHANT_ID + serviceTypeId + orderId + data.amount + configs.REMITA_API_KEY,
  );

  const request = {
    url: '/paymentinit',
    method: 'POST',
    data: payload,
    headers: {
      authorization: `remitaConsumerKey=${configs.REMITA_MERCHANT_ID},remitaConsumerToken=${apiHash}`,
    },
  };

  // this logic is because the response for generate rrr is in the form of jsonp
  // jsonp ({"statuscode":"025","RRR":"290008278737","status":"Payment Reference generated"})
  return instance
    .request(request)
    .then((res) => {
      if (res && res.toString().search('jsonp') >= 0) {
        return JSON.parse(
          res
            .toString()
            .split('(')[1]
            .split(')')[0],
        );
      }
    })
    .catch((e) => Promise.reject(e));
};

/**
 * RESPONSE INTERCEPTOR
 */
instance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response.data),
);

export default generateRRR;
