import React from 'react';
import { isEmpty, get } from 'lodash';
import moment from 'moment';
import { Formik, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../redux/loader/LoaderActions';
import { POST } from '../../services/rest.service';
import TextField from '../UI/TextField';
import convertJsonToFormData, { userSchema } from '../../helpers/helper';
import { VERIFYME_TYPE } from '../../helpers/constants';
import OTPFormField from '../global/ClientVerification/FormFields/OTP';

import FIleField from '../UI/FIleField';
import DisplayDocument from '../global/DisplayDocument';
import PhoneNumber from '../global/PhoneNumber';
import CountryCodePickerInput from '../shared/CountryCodePickerInput';

const DirectorShareholderForm = (props) => {
  const {
    isEdit,
    toggle,
    type,
    shareValues,
    onSubmit,
    isDocVerified,
    // verifyMeData,
    setIsDocVerified,
    showDoc,
    verifyDocument,
    showOTPField,
    verifyUserOtp,
    sendMsgOtp,
    id,
  } = props;
  const dispatch = useDispatch();

  return (
    <div
      className="modal right fade right-slide in"
      id="myModal2"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title" id="myModalLabel2">
              {isEdit ? 'Edit' : 'Add'} {type === 'director' ? 'Director' : 'Shareholder'}
            </h4>
          </div>

          <div className="modal-body">
            <Formik
              initialValues={{
                first_name: get(shareValues, 'first_name', ''),
                last_name: get(shareValues, 'last_name', ''),
                address: get(shareValues, 'address', ''),
                dob: get(shareValues, 'dob', ''),
                nationality: get(shareValues, 'nationality', ''),
                mobile_number: get(shareValues, 'mobile_number', ''),
                email: get(shareValues, 'email', ''),
                share_percentage: get(shareValues, 'share_percentage', ''),
                home_address_proof: '',
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                // console.log('>> values',values)
                dispatch(showLoading());
                try {
                  const payLoad = {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    address: values.address,
                    dob: values.dob,
                    nationality: values.nationality,
                    mobile_number: values.mobile_number,
                    email: values.email,
                    home_address_proof: values.home_address_proof,
                  };
                  let url = '';
                  let msg = '';
                  if (isEdit) {
                    // id of director or shareholder
                    payLoad.id = id;
                    if (type === 'director') {
                      url = '/users/updateDirector';
                      msg = 'Director Updated successfully';
                    } else {
                      url = '/users/updateShareholder';
                      msg = 'Shareholder Updated successfully';
                      payLoad.share_percentage = values.share_percentage;
                    }
                  } else {
                    if (type === 'director') {
                      url = '/users/addDirector';
                      msg = 'Director Added successfully';
                    } else {
                      url = '/users/addShareholder';
                      msg = 'Shareholder Added successfully';
                      payLoad.share_percentage = values.share_percentage;
                    }
                  }
                  const response = await POST(url, convertJsonToFormData(payLoad));
                  //console.log('response', response);
                  resetForm();
                  setSubmitting(false);
                  onSubmit(response);
                  dispatch(showModal(msg, true));
                } catch (error) {
                  dispatch(showModal(error.message));
                } finally {
                  dispatch(hideLoading());
                }
              }}
              validationSchema={userSchema}
            >
              {(renderProps) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                } = renderProps;

                let showVerificationBtn = false;
                if (
                  values.dob &&
                  values.mobile_number &&
                  values.first_name &&
                  values.last_name &&
                  !isDocVerified
                  // && isEmpty(verifyMeData)     // >> Verifyme Verification related
                ) {
                  showVerificationBtn = true;
                }

                return (
                  <form className="row" onSubmit={handleSubmit}>
                    <div className="col-sm-6 mt-2">
                      <div className="text-left">
                        <label className="v2-text-label v2-text">First Name</label>
                        <TextField
                          id="first_name"
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'first_name') && getIn(errors, 'first_name')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6  mt-2">
                      <div className="text-left">
                        <label className="v2-text-label v2-text">Last Name</label>
                        <TextField
                          id="last_name"
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'last_name') && getIn(errors, 'last_name')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                      <div className="text-left">
                        <label className="v2-text-label v2-text">Email</label>
                        <TextField
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'email') && getIn(errors, 'email')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                      <div className="text-left">
                        <label className="v2-text-label v2-text">Phone Number</label>
                        <CountryCodePickerInput
                          value={values.mobile_number}
                          onChange={(value) => {
                            setFieldValue('mobile_number', value);
                            if (value) {
                              const oldPhone = get(shareValues, 'mobile_number');
                              if (value !== oldPhone) {
                                setIsDocVerified(false);
                              } else if (value === oldPhone) {
                                setIsDocVerified(true);
                              }
                            } else {
                              setIsDocVerified(false);
                            }
                          }}
                          onBlur={handleBlur}
                          error={getIn(touched, 'mobile_number') && getIn(errors, 'mobile_number')}
                        />
                        <PhoneNumber
                          value={values.mobile_number}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue('mobile_number', value);
                            if (value) {
                              const oldPhone = get(shareValues, 'mobile_number');
                              if (value !== oldPhone) {
                                setIsDocVerified(false);
                              } else if (value === oldPhone) {
                                setIsDocVerified(true);
                              }
                            } else {
                              setIsDocVerified(false);
                            }
                          }}
                          onBlur={handleBlur}
                          error={getIn(touched, 'mobile_number') && getIn(errors, 'mobile_number')}
                        />
                      </div>
                    </div>
                    {type !== 'director' && (
                      <div className="col-sm-12 mt-4">
                        <div className="text-left">
                          <label className="v2-text-label v2-text">
                            Percentage of Shareholding
                          </label>

                          <TextField
                            id="share_percentage"
                            type="text"
                            className="form-control"
                            placeholder="00"
                            value={values.share_percentage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              getIn(touched, 'share_percentage') &&
                              getIn(errors, 'share_percentage')
                            }
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-sm-12 mt-4">
                      <div className="text-left">
                        <label className="v2-text-label v2-text">Date Of Birth</label>
                        <TextField
                          id="dob"
                          type="date"
                          className="form-control"
                          value={values.dob}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'dob') && getIn(errors, 'dob')}
                          max={moment().format('YYYY-MM-DD')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                      <div className="text-left">
                        <label className="v2-text-label v2-text">Nationality</label>
                        <TextField
                          id="nationality"
                          type="text"
                          className="form-control"
                          placeholder="Nationality"
                          value={values.nationality}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'nationality') && getIn(errors, 'nationality')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4"></div>
                    <div className="col-sm-12 mt-4">
                      <div className="text-left">
                        <label className="v2-text-label v2-text">Residential Address</label>
                        <TextField
                          id="address"
                          type="text"
                          className="form-control"
                          placeholder="Residential Address"
                          value={values.address}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getIn(touched, 'address') && getIn(errors, 'address')}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-4">
                      {shareValues.home_address_proof && (
                        <DisplayDocument
                          label="Proof of Home Address"
                          onClick={() => showDoc(shareValues.home_address_proof)}
                          type="file"
                          fileColClassName="form-document"
                          nolabel={true}
                        />
                      )}

                      <div
                        className="fileUploads"
                        style={{ background: values.home_address_proof ? '#01a8af' : '' }}
                      >
                        <span>
                          <i className="fa fa-file-text-o" aria-hidden="true"></i> Proof of Home
                          Address
                        </span>
                        <FIleField
                          type="file"
                          className={`${
                            getIn(touched, 'home_address_proof') &&
                            getIn(errors, 'home_address_proof')
                              ? 'Upload is-invalid'
                              : 'Upload'
                          }`}
                          name="home_address_proof"
                          onChange={(event) =>
                            setFieldValue('home_address_proof', event.target.files[0])
                          }
                          onBlur={handleBlur}
                        />
                        {getIn(touched, 'home_address_proof') &&
                        getIn(errors, 'home_address_proof') ? (
                          <div className="invalid-feedback mb-3">
                            {getIn(errors, 'home_address_proof')}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* NIN/BVN/DL verification button */}
                    {showVerificationBtn && !showOTPField && (
                      <div className="col-sm-12 mt-4">
                        <button
                          className="form-control submitbut"
                          type="button"
                          onClick={async () => {
                            const sft = await setFieldTouched('mobile_number', true);
                            if (!sft?.mobile_number) {
                              verifyDocument(VERIFYME_TYPE, {
                                referenceId: values.mobile_number,
                                dob: moment(values.dob).format('DD-MM-YYYY'),
                                firstname: values.first_name,
                                lastname: values.last_name,
                              });
                            }
                          }}
                        >
                          Verify Phone Number
                        </button>
                      </div>
                    )}
                    {/* NIN/BVN/DL verification OTP FIELD */}
                    {showOTPField && (
                      <div className="col-sm-12 mt-4">
                        <div className="text-left">
                          <OTPFormField
                            {...renderProps}
                            phone={values.mobile_number}
                            verifyUserOtp={(phone, otp) => verifyUserOtp(phone, otp, 'director')}
                            resendOtp={sendMsgOtp}
                          />
                        </div>
                      </div>
                    )}
                    <div className="col-sm-12 mt-4">
                      <button
                        className=" form-control submitbut mt-2"
                        type="submit"
                        disabled={!isDocVerified}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DirectorShareholderForm;
