import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { capitalize, isEmpty, startCase, camelCase } from 'lodash';
import swal from 'sweetalert';
import AppLoader from '../../../components/loaders/AppLoader';
import { showModal } from '../../../redux/error/ErrorAction';
import { hideLoading, showLoading } from '../../../redux/loader/LoaderActions';
import { GET, POST, DELETE } from '../../../services/rest.service';
import Chat from '../../../components/UI/Chat';
import docImage from '../../../../assets/img/doc.png';
import {
  accessGeneralOrCargoType,
  getJobServices,
  weightFormat,
  getJobStatus,
  getPortName,
} from '../../../helpers/utils';
import { LeftArrowSvg } from '../../../components/UI/CommanUI';
import { showDocumentModal } from '../../../redux/user/UserActions';
import { useChat } from '../../../helpers/customHooks';
import DisputeModal from '../../../components/Modals/DisputeModal';

const RejectedJobDetails = (props) => {
  const [jobDetails, setDetails] = useState({});
  const dispatch = useDispatch();
  const params = new URLSearchParams(props.location.search).get('id');
  const job_id = params;
  const serviceData = useSelector((state) => state.enumData);
  const [chatbox, setChatbox] = useState(false);
  const [chatlist, setChatlist] = useState([]);
  const [msg, setMsg] = useState('');
  const { user } = useSelector((state) => state.user);
  const [unreadCount, setUnreadCount] = useState(0);
  const [disputeModal, setDisputeModal] = useState(false);

  const showDoc = (docc) => dispatch(showDocumentModal(docc));

  //get Job Deta ils
  const getJobDetails = () => {
    dispatch(showLoading());
    GET(`/jobs/getDetails/${job_id}`)
      .then(({ data }) => {
        // console.log(data);
        setDetails(data.job);
        dispatch(hideLoading());
      })
      .catch((err) => {
        dispatch(showModal(err.message));
        dispatch(hideLoading());
      });
  };

  useEffect(() => {
    getJobDetails();
  }, []);

  // CHAT Related
  const chatName = user.type === 'application_client' ? 'Us' : 'Client';
  const chatBlockId = chatName === 'Client' ? 'chat-tops' : 'chat-top';

  // initialize and subcsribe to chat here
  const handleChatEvent = (data1) => {
    if (data1.chat_type === 'client') {
      if (!chatbox) {
        // set unread count when chat is not openend
        setUnreadCount((prevState) => prevState + 1);
      }
      setChatlist((prevState) => [...prevState, data1]);
      const objDiv = document.getElementById(chatBlockId);
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }

    dispatch(showModal('you have a new message', true));
  };
  useChat(user.uuid, chatbox, handleChatEvent);

  //chat box implemented code
  const getChat = () => {
    GET(`/chats/getByJob/${job_id}`)
      .then(({ data }) => {
        // console.log(data);
        if (user.type === 'application_client') {
          const oc = data.chats.operator?.length > 0 ? data.chats.operator.reverse() : [];
          setChatlist(oc);
        } else {
          const cc = data.chats.client?.length > 0 ? data.chats.client.reverse() : [];
          setChatlist(cc);
        }
        const objDiv = document.getElementById(chatBlockId);
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };

  const chatToggle = () => {
    if (!chatbox) {
      // fetch chat list when user opens chat
      getChat();
      // when chat is openend clear the unread count
      setUnreadCount(0);
    }
    setChatbox(!chatbox);
  };

  const sendMessage = () => {
    let message = 'Hi';
    if (!isEmpty(msg)) {
      message = msg;
    }

    const payLoad = {
      job_uuid: job_id,
      sender_uuid: user.uuid,
      message: message,
      chat_type: 'client',
    };
    if (user.type === 'application_client') {
      payLoad.receiver_uuid = jobDetails.operator_uuid;
    } else if (user.type === 'managenmnt_operator') {
      payLoad.receiver_uuid = jobDetails.created_by;
    }

    POST(`/chats/addMessage`, payLoad)
      .then(({ data }) => {
        setChatlist((prevState) => [...prevState, data.chat]);
        const objDiv = document.getElementById(chatBlockId);
        if (objDiv) {
          objDiv.scrollTop = objDiv.scrollHeight;
        }
        // getChat();
      })
      .catch((err) => {
        dispatch(showModal(err.message));
      });
  };
  const sendButton = () => {
    sendMessage();
    setMsg('');
  };

  // Press enter to send a message
  const handleTextChange = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
      setMsg('');
    }
  };
  //msg state change
  const handleOnChange = (e) => {
    setMsg(e.target.value);
  };
  //end chat code

  // handle delete job
  const handleDeleteJob = () => {
    swal({
      title: 'Are you sure?',
      text: `Are you sure you want to Delete Job?`,
      icon: 'warning',
      buttons: ['No', 'Yes'],
    }).then((result) => {
      if (result) {
        dispatch(showLoading());
        DELETE(`/jobs/${job_id}`)
          .then(() => {
            dispatch(showModal('Job Deleted Sucessfully!', true));
            dispatch(hideLoading());
            props.history.goBack();
          })
          .catch((err) => {
            dispatch(hideLoading());
            dispatch(showModal(err.message));
            // history.goBack();
          });
      }
    });
  };

  const toggleDisputeModal = () => setDisputeModal((s) => !s);

  if (isEmpty(serviceData)) {
    return <AppLoader />;
  }

  const jobUnderReview =
    jobDetails.clearing_status === '6' ||
    jobDetails.clearing_status === '11' ||
    jobDetails.forwarding_status === '6';

  const jobStatusRejected =
    jobDetails.clearing_status === '3' ||
    jobDetails.forwarding_status === '3' ||
    jobDetails.clearing_status === '12';

  const rejectedJob = jobStatusRejected && jobDetails.operator_comment;
  const showDeleteJob = rejectedJob || jobUnderReview;

  const showUpdateJob =
    jobDetails.clearing_status === '6' ||
    jobDetails.forwarding_status === '6' ||
    jobDetails.clearing_status === '3' ||
    jobDetails.forwarding_status === '3' ||
    jobDetails.clearing_status === '11' ||
    jobDetails.clearing_status === '12';

  const showOpenDispute =
    jobDetails?.disputes?.length === 0 &&
    (jobDetails?.can_clearing_dispute || jobDetails?.can_forwarding_dispute);

  return (
    <>
      <div id="tabblock4" className="tabcontent rej-job-details">
        <div className="bodycont">
          <div className="">
            <div className="tab-pane active" id="resp-tab7">
              <>
                <div>
                  <h2>
                    <span
                      onClick={() => {
                        props.history.goBack();
                      }}
                      style={{ cursor: 'pointer', float: 'left' }}
                    >
                      <LeftArrowSvg />
                    </span>
                    Job Details
                  </h2>
                </div>

                <div className="servicetab">
                  <div className="operator-info">
                    <div>
                      <div className="row">
                        <div className="col-lg-9 col-sm-12">
                          <h5>
                            {' '}
                            {capitalize(serviceData.job.category[jobDetails.category]) || 'N/A'}
                          </h5>
                          <h6>{jobDetails.job_id || 'N/A'}</h6>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                          <h4>{getJobServices(jobDetails)}</h4>
                        </div>
                      </div>

                      <hr />
                      <h6>Job Details</h6>
                      {rejectedJob && (
                        <p className="alert alert-danger">
                          Reason for Rejection:{' '}
                          <strong>{jobDetails.operator_comment || 'N/A'}</strong>
                        </p>
                      )}
                      <p>
                        Status: <strong>{getJobStatus(jobDetails, serviceData)}</strong>
                      </p>

                      {jobDetails?.clearing_discharge_port && (
                        <p>
                          Clearing Port of Discharge:{' '}
                          {startCase(
                            camelCase(
                              getPortName(jobDetails?.clearing_discharge_port, serviceData),
                            ),
                          )}
                        </p>
                      )}
                      {jobDetails?.port_of_discharge && (
                        <p>
                          Pick-up Location:{' '}
                          {startCase(
                            camelCase(getPortName(jobDetails?.port_of_discharge, serviceData)),
                          )}
                        </p>
                      )}

                      {jobDetails.category === 1 && (
                        <Fragment>
                          <p>
                            Type of vehicle:{' '}
                            {capitalize(serviceData.job.vehicle_type[jobDetails.type]) || 'N/A'}{' '}
                          </p>
                          {jobDetails.type !== 1 && (
                            <p>No of containers : {jobDetails.no_of_containers || 'N/A'} </p>
                          )}
                          <p>
                            Expected date of arrival :{' '}
                            {moment(jobDetails.date_of_arrival).format('DD/MM/YYYY') || 'N/A'}{' '}
                          </p>
                          {jobDetails?.containers?.length > 0 &&
                            jobDetails.containers.map((x, index) => (
                              <div className="row" key={index}>
                                <hr />
                                <h6>Container {index + 1}</h6>
                                <p>Description of load : {x.load_description || 'N/A'}</p>
                                {x.vehicles.map((v, i) => (
                                  <div className="col-lg-4 col-sm-12" key={i}>
                                    <div className="alert alert-info">
                                      {jobDetails.type === 1 ? (
                                        <h6>Vehicle Details</h6>
                                      ) : (
                                        <h6>Vehicle {i + 1}</h6>
                                      )}
                                      <ul>
                                        <li>Brand: {v.brand}</li>
                                        <li>Year: {v.year}</li>
                                        <li>Model: {v.model}</li>
                                        <li>
                                          Conditions:{' '}
                                          {capitalize(
                                            serviceData.job.vehicle_state[v.vehicle_state],
                                          )}
                                        </li>
                                        <li>VREG Number : {v.vreg_no || 'N/A'}</li>
                                      </ul>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          {jobDetails?.vehicles?.length > 0 && (
                            <>
                              <hr />
                              <div className="row mx-0">
                                {jobDetails.vehicles.map((x, index) => (
                                  <div className="col-lg-4 col-sm-12" key={index}>
                                    <div className="alert alert-info">
                                      {jobDetails.type === 1 ? (
                                        <h6>Vehicle Details</h6>
                                      ) : (
                                        <h6>Vehicle {index + 1}</h6>
                                      )}
                                      <p>Brand: {x.brand}</p>
                                      <p>Year: {x.year}</p>
                                      <p>Model: {x.model}</p>
                                      <p>
                                        Conditions:{' '}
                                        {capitalize(serviceData.job.vehicle_state[x.vehicle_state])}
                                      </p>
                                      <p>VREG Number : {x.vreg_no || 'N/A'}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                        </Fragment>
                      )}
                      {jobDetails.category === 2 && (
                        <Fragment>
                          <p>No of containers : {jobDetails.no_of_containers || 'N/A'} </p>
                          <p>
                            Type of cargo :{' '}
                            {accessGeneralOrCargoType(jobDetails, serviceData) || 'N/A'}{' '}
                          </p>
                          {jobDetails.type_of_cargo === 1 && (
                            <p>Item Name : {jobDetails.single_item_title || 'N/A'} </p>
                          )}
                        </Fragment>
                      )}
                      {jobDetails.category === 3 && (
                        <Fragment>
                          <p>Tonnage : {jobDetails.tonnage || 'N/A'} </p>
                          <p>
                            Type of cargo : {accessGeneralOrCargoType(jobDetails, serviceData)}{' '}
                          </p>
                          <p>Item Name : {jobDetails.single_item_title || 'N/A'} </p>
                          <br />
                        </Fragment>
                      )}
                      {(jobDetails.paar_link ||
                        jobDetails.lading_link ||
                        jobDetails.performa_invoice ||
                        jobDetails.marine_inspection_report) && (
                        <div className="row mx-0">
                          <hr />
                          {jobDetails.paar_link && (
                            <div className="col-lg-4 col-sm-12">
                              <h6>PAAR File</h6>
                              <span onClick={() => showDoc(jobDetails.paar_link)}>
                                <img src={docImage} title="GoAgent" alt="" />
                              </span>
                            </div>
                          )}
                          {jobDetails.lading_link && (
                            <div className="col-lg-4 col-sm-12">
                              <h6>Bill of Lading File</h6>
                              <span onClick={() => showDoc(jobDetails.lading_link)}>
                                <img src={docImage} title="GoAgent" alt="" />
                              </span>
                            </div>
                          )}
                          {jobDetails.performa_invoice && (
                            <div className="col-lg-4 col-sm-12">
                              <h6>Proforma Invoice</h6>
                              <span onClick={() => showDoc(jobDetails.performa_invoice)}>
                                <img src={docImage} title="GoAgent" alt="" />
                              </span>
                            </div>
                          )}
                          {jobDetails.marine_inspection_report && (
                            <div className="col-lg-4 col-sm-12">
                              <h6>Marine Inspection Report</h6>
                              <span onClick={() => showDoc(jobDetails.marine_inspection_report)}>
                                <img src={docImage} title="GoAgent" alt="" />
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {jobDetails.has_forwarding === 1 && (
                        <Fragment>
                          <hr />
                          <h6>Transport Service Details</h6>

                          <p>
                            Port of discharge :{' '}
                            {startCase(
                              camelCase(serviceData.port_locations[jobDetails.port_of_discharge]),
                            )}{' '}
                          </p>
                          <p>Destination : {jobDetails.destination || 'N/A'} </p>
                          <p>
                            State:{' '}
                            {serviceData.states.find((s) => s.uuid === jobDetails.destination_state)
                              ?.state || 'N/A'}{' '}
                          </p>
                        </Fragment>
                      )}
                      {jobDetails.children_packages && jobDetails.children_packages.length ? (
                        <Fragment>
                          {jobDetails.children_packages.map((x, index) => (
                            <Fragment key={index}>
                              <hr />
                              {index === 0 && <h6>Unbundled Jobs</h6>}
                              <h6>
                                Job {index + 1} : {x.job_id}
                              </h6>

                              <p>Size: {capitalize(serviceData.truck.size[x.size])}</p>
                              <p>Weight: {weightFormat(x.weight)}</p>
                              <div className="alert alert-info">
                                <h4>Truck Requirement</h4>
                                <ul>
                                  <li>size: {x.truck.size}</li>
                                  {/* <li>axel: {x.truck.axel}</li> */}
                                  {jobDetails.category !== 3 && <li>hooks: {x.truck.hooks}</li>}
                                </ul>
                              </div>
                            </Fragment>
                          ))}
                        </Fragment>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>

                <div className="buttonsec relative-right-10">
                  <button onClick={() => props.history.goBack()} className="submitbut btn-light">
                    Back
                  </button>
                  {user.type === 'application_client' && (
                    <>
                      {/* <button
                        onClick={() => {
                          props.history.push('/create-service-request');
                        }}
                        className="submitbut"
                      >
                        Post button New Job
                      </button> */}
                      {showUpdateJob && (
                        <button
                          onClick={() => {
                            props.history.push('/client/job/create-service-request', {
                              ...jobDetails,
                            });
                          }}
                          className="submitbut"
                        >
                          Update Job
                        </button>
                      )}
                      {showDeleteJob && (
                        <button onClick={handleDeleteJob} className="delete-btn">
                          Delete Job <i className="fa fa-trash"></i>
                        </button>
                      )}
                      {showOpenDispute && (
                        <button onClick={toggleDisputeModal} className="submitbut">
                          {jobDetails.insurance_purchashed_on
                            ? 'Request Insurance Indemnity'
                            : 'Open Dispute'}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {rejectedJob && (
        <Chat
          name={chatName}
          chatbox={chatbox}
          user={user}
          chatToggle={chatToggle}
          chatlist={chatlist?.length > 0 ? chatlist : []}
          value={msg}
          handleTextChange={handleTextChange}
          handleOnChange={handleOnChange}
          sendButton={sendButton}
          unreadMsgsCount={unreadCount}
        />
      )}
      {disputeModal && (
        <DisputeModal
          disputeModal={disputeModal}
          toggleDisputeModal={toggleDisputeModal}
          jobDetails={jobDetails}
          container="client-page"
          refreshData={getJobDetails}
        />
      )}
    </>
  );
};

export default RejectedJobDetails;
