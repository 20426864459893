import config from '../../config';
import Logo from '../../assets/img/logo.png';

// Paystack
export const getPaystackConfig = (data) => {
  return {
    reference: new Date().getTime().toString(),
    email: data.user.email || '',
    amount: data.amount || 0,
    currency: 'NGN',
    publicKey: config.PAYSTACK_KEY,
    text: 'Pay with Paystack',
    onSuccess: data.onSuccess,
    onClose: data.onClose,
  };
};

// Flutterwave
export const getFlutterwaveConfig = (data) => {
  return {
    public_key: config.FLUTTERWAVE_KEY,
    tx_ref: Date.now(),
    amount: data.amount || 0,
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: data.user.email,
      phonenumber: data.user.phonenumber,
      name: data.user.name,
    },
    customizations: {
      title: 'GoAgent',
      description: 'Payment for Job',
      logo: Logo,
    },
    text: 'Pay with Flutterwave',
    callback: data.callback,
    onClose: data.onClose,
  };
};

// Remita
export const getRemitaConfig = (data) => {
  return {
    remitaData: {
      key: config.REMITA_PUBLIC_KEY, // enter your key here
      customerId: data.user.uuid,
      firstName: data.user.first_name,
      lastName: data.user.last_name,
      email: data.user.email,
      amount: data.amount,
      narration: 'Payment for Job',
      onSuccess: data.onSuccess,
      onError: data.onError,
      onClose: data.onClose,
    },
    text: 'Pay with Remita', //text to show on button
    // add a 'live' prop to use the live urls/keys
  };
};

// Paypal
export const getPaypalConfig = (data) => {
  return {
    amount: data.amount,
    options: {
      clientId: config.CLIENT_ID,
      disableFunding: 'card,credit,bancontact',
    },
    onSuccess: data.onSuccess,
    onCancel: data.onCancel,
  };
};

export const getHabariConfig = (data) => {
  return {
    habriData: {
      key: config.HABARI_PUBLIC_KEY, // enter your key here
      customerId: data.user.uuid,
      firstName: data.user.first_name,
      lastName: data.user.last_name,
      email: data.user.email,
      amount: data.amount,
      narration: 'Payment for Job',
      onSuccess: data.onSuccess,
      onError: data.onError,
      onClose: data.onClose,
    },
    text: 'Pay with Remita', //text to show on button
    // add a 'live' prop to use the live urls/keys
  };
};
