import { useFormik } from 'formik';
import { capitalize, startCase, camelCase } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  category: Yup.number().required('Please select category'),
  date_of_arrival: Yup.date().required('Please select date of arrival'),
  type: Yup.string().when('category', (category, schema) => {
    if (category == 1) {
      return schema.required('Please select type');
    }
    return schema;
  }),
  single_item_title: Yup.string().when('category', (category, schema) => {
    console.log('>> category', category);
    if (category != 1) {
      return schema.required('Please enter item name');
    }
    return schema;
  }),
  port_of_discharge: Yup.string().required('Please select port of discharge'),
  email: Yup.string()
    .email('Please enter valid email')
    .required('Please enter email'),
  phone: Yup.string(),
});

const ClearingServiceTab = ({ onSubmit }) => {
  const serviceData = useSelector((state) => state.enumData);
  const [token, setToken] = useState(null);
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    setFieldError,
    dirty,
    isValid,
  } = useFormik({
    initialValues: {
      category: '1',
      itemOrVehicle: '',
      port_of_discharge: '',
      date_of_arrival: '',
      container_size: '',
      pickup_location: '',
      destination: '',
      email: '',
      phone: '',
      type: '1',
      single_item_title: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.job_type = 'clearing';
      onSubmit(values, resetForm, (errors) => {
        if (errors) {
          Object.keys(errors).forEach((key) => {
            setFieldError(key, errors[key][0]);
          });
        }
      });
    },
  });

  const handleVerify = (newToken) => {
    setToken(newToken);
  };

  return (
    <form onSubmit={handleSubmit} id="service-form">
      <Row>
        <Col md={6} lg={3} className="mt">
          <label style={{ marginBottom: '0px' }}>Select Category</label>
          <select
            name="category"
            className="form-control"
            onChange={(e) => {
              handleChange(e);
            }}
            value={values.category}
          >
            {Object.keys(serviceData.job.category).map((key) => (
              <option value={key} key={key}>
                {capitalize(serviceData.job.category[key])}
              </option>
            ))}
          </select>
          <span className="text-danger">{touched.category && errors.category}</span>
        </Col>

        {values.category === '1' && (
          <Col md={6} lg={3} className="mt">
            <label style={{ marginBottom: '0px' }}>Select Type</label>
            <select
              name="type"
              className="form-control"
              onChange={handleChange}
              value={values.type}
            >
              {Object.keys(serviceData.job.vehicle_type).map((key) => (
                <Fragment key={key}>
                  {key !== '3' && (
                    <option value={key} key={key}>
                      {capitalize(serviceData.job.vehicle_type[key])}
                    </option>
                  )}
                </Fragment>
              ))}
            </select>

            <span className="text-danger">{touched.vehicle && errors.vehicle}</span>
          </Col>
        )}

        {(values.category === '2' || values.category === '3') && (
          <Col md={6} lg={3} className="mt">
            <label style={{ marginBottom: '0px' }}>Item Name {'(Separated by comma) '}</label>
            <input
              name="single_item_title"
              type="text"
              className="form-control"
              placeholder="item 1, item 2"
              onChange={handleChange}
              value={values.single_item_title}
            />
            <span className="text-danger">{touched.tonnage && errors.tonnage}</span>
          </Col>
        )}

        <Col md={6} lg={3} className="mt">
          <label style={{ marginBottom: '0px' }}>Port of Discharge</label>
          <select
            name="port_of_discharge"
            type="text"
            className="form-control"
            onChange={handleChange}
            value={values.port_of_discharge}
          >
            <option value="">Select Port</option>
            {serviceData?.ports?.length > 0 &&
              serviceData?.ports.map((obj) => (
                <option value={obj.uuid} key={obj.uuid}>
                  {startCase(camelCase(obj.port_of_operation))}
                </option>
              ))}
          </select>
          <span className="text-danger">
            {touched.port_of_discharge && errors.port_of_discharge}
          </span>
        </Col>

        <Col md={6} lg={3} className="mt">
          <label style={{ marginBottom: '0px' }}>Expected date of arrival</label>
          <input
            name="date_of_arrival"
            type="date"
            className="form-control"
            onChange={handleChange}
            value={values.date_of_arrival}
            min={new Date().toISOString().split('T')[0]}
          />
          <span className="text-danger">{touched.date_of_arrival && errors.date_of_arrival}</span>
        </Col>

        <Col md={6} lg={4} className="mt">
          <label style={{ marginBottom: '0px' }}>Email</label>
          <input
            name="email"
            type="email"
            className="form-control"
            placeholder="Enter your Email"
            onChange={handleChange}
            value={values.email}
          />
          <span className="text-danger">{touched.email && errors.email}</span>
        </Col>

        <Col md={6} lg={4} className="mt">
          <label style={{ marginBottom: '0px' }}>Phone</label>
          <input
            name="phone"
            type="text"
            className="form-control"
            placeholder="Enter your Phone"
            onChange={handleChange}
            value={values.phone}
          />
        </Col>
        <Col md={6} lg={4} className="mt">
          <div className="text-right">
            <button className="post-button w-100" type="submit" disabled={!(isValid && dirty)}>
              Submit
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default ClearingServiceTab;
