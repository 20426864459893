import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import serviceImg2 from '../../../assets/img/service-img2.jpg';
import serviceImg3 from '../../../assets/img/service-img3.jpg';
import serviceImg4 from '../../../assets/img/service-img4.jpg';
// import Apple from '../../../assets/img/apple-store.png';
// import Google from '../../../assets/img/google-store.png';
import AccountabilityImg from '../../../assets/img/accountability.jpg';
import heroBackground from '../../../assets/img/hero-background.jpg';
import InnovationImg from '../../../assets/img/innovation.jpg';
import CustomerOrientedImg from '../../../assets/img/customer-oriented.jpg';
import ProfessionalismImg from '../../../assets/img/professionalism.jpg';
import StakeholderEngagementImg from '../../../assets/img/goagent_team.jpg';
import portVideo from '../../../assets/video/portvideo.mp4';
import PublicFooter from '../../components/global/PublicFooter';
import PublicHeader from '../../components/global/PublicHeader';
import { GET } from '../../services/rest.service';
import config from '../../../config';
import ContactUsForm from '../../components/global/ContactUsForm';
import { applicationUserType } from '../../helpers/constants';
import TestimonialProfileDefault from '../../../assets/img/placeHolderImage.png';
import companyLogo1 from '../../../assets/img/company-logo1.png';
import companyLogo2 from '../../../assets/img/company-logo2.png';
import companyLogo3 from '../../../assets/img/company-logo3.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ServiceCard from './hero/ServiceCard';
import Slider from 'react-slick';
import configObject from '../../../config';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Home = (props) => {
  const user = useSelector((state) => state.user);
  const [testimonials, setTestimonials] = useState([]);
  const [value, setValue] = useState('1');
  useEffect(() => {
    // fetch
    GET('users/getTestimonial', { page: 1, per_page: 10 })
      .then(({ data }) => {
        setTestimonials(data.testimonials);
      })
      .catch((err) => console.log('error fetching testimonials'));
  }, []);

  // redirecting user to his particular dashboard, if user visits this page while logged in
  if (get(user, 'isLoggedIn', false)) {
    const { url } = props.match;
    if (applicationUserType[get(user, 'user.type')]) {
      return (
        <Redirect from={url} to={`/${applicationUserType[get(user, 'user.type')]}/dashboard`} />
      );
    }
  }
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 100,
    cssEase: 'linear',
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 4096,
        settings: {
          slidesToShow: 3, // increase this when a new logo is added
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1769,
        settings: {
          slidesToShow: 3, // increase this when a new logo is added
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1569,
        settings: {
          slidesToShow: 3, // increase this when a new logo is added
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1369,
        settings: {
          slidesToShow: 3, // increase this when a new logo is added
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1169,
        settings: {
          slidesToShow: 3, // increase this when a new logo is added
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // increase this when a new logo is added
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>GoAgent Clearing and Transport</title>
      </Helmet>
      <div id="home-page">
        <div className="video-banner1">
          <div className="video-banner">
            {/* <video width="100%" height="100%" className="videot1" autoPlay={'autoplay'} muted loop>
              <source src={portVideo} type="video/mp4" />
            </video> */}
            <img
              width="100%"
              height="100%"
              style={{ objectFit: 'cover' }}
              src={heroBackground}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="website-home">
        <PublicHeader />
      </div>

      <section id="hero" className="hero d-flex align-items-center">
        <div className="container mrl-1 mobile-margin">
          <div className="row">
            <div className="col-sm-12">
              <h2 data-aos="fade-up" className="heading-home1 text-center mb-3">
                Post your cargo clearing and transport jobs for instant quotes from multiple
                reliable agents and transporters, without commitment.
              </h2>
              {/* <h4 data-aos="fade-up" className="heading-home text-center">
                We register reliable clearing agents and transporters, have them bid on your job,
                and monitor the execution and delivery. No delay, no demurrage, no middlemen.
              </h4> */}
              {/* <div className="d-flex flex-column align-items-center" data-aos="fade-up"> */}
              {/* <a href="coming-soon.html">
                  <img src={Apple} alt="" />
                </a>

                <a href="coming-soon.html">
                  <img src={Google} alt="" />
                </a> */}
              {/* </div> */}

              {/* <div data-aos="fade-up" className="container">
                <div className="logo">
                  <img src={Apple} alt="" />
                </div>
                <div className="logo">
                  <img src={Google} alt="" />
                </div>
              </div> */}
            </div>
            <GoogleReCaptchaProvider reCaptchaKey={configObject.CAPTCHA_SITE_KEY}>
              <ServiceCard />
            </GoogleReCaptchaProvider>
            {/* create 5 tabs  */}
          </div>
        </div>
      </section>

      <section id="testimonial" className="testimonial">
        <div className="container">
          <section id="valuable-clients" className="valuable-clients">
            <div className="container">
              <div className="trusted-text">Trusted by leading brands</div>
              <div>
                <Slider {...settings}>
                  <div className="logo">
                    <img
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      src={companyLogo1}
                      alt="company-logo1"
                    />
                  </div>
                  <div className="logo">
                    <img
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      src={companyLogo2}
                      alt="company-logo2"
                    />
                  </div>
                  <div className="logo">
                    <img
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      src={companyLogo3}
                      alt="company-logo3"
                    />
                  </div>
                </Slider>
                <div className="clearfix"></div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-7 w-100">
              {/* <div className="d-flex testimonial-logo">
                <img src={companyLogo1} alt="" />
                <img src={companyLogo2} alt="" />
                <img src={companyLogo3} alt="" />
              </div> */}
              <h5>Testimonials</h5>
              <h6>GoAgent’s Reviews</h6>
              <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                <div className="carousel-inner text-center">
                  {testimonials.length > 0 &&
                    testimonials.map((obj, i) => (
                      <div className={`item ${i == 0 ? 'active' : ''}`} key={i}>
                        <blockquote>
                          <div className="row">
                            <div className="col-sm-12" style={{ minHeight: '155px' }}>
                              <p>{obj.comment}</p>
                              <small>
                                <b style={{ paddingLeft: 5, textTransform: 'capitalize' }}>
                                  {`${obj.name}, ${obj.designation}`}
                                </b>
                                <br />
                                <span style={{ paddingLeft: 20 }}>
                                  {moment(obj.created_at).format('Do MMMM YYYY')}
                                </span>
                              </small>
                            </div>
                          </div>
                        </blockquote>
                      </div>
                    ))}
                </div>
                <ol className="carousel-indicators">
                  {testimonials.length > 0 &&
                    testimonials.map((obj, i) => (
                      <li
                        data-target={
                          testimonials.length === 1
                            ? '#quote-carousel-single-item'
                            : '#quote-carousel'
                        }
                        data-slide-to="0"
                        className={i === 0 ? 'active' : ''}
                        key={i}
                      >
                        <img
                          className="img-responsive "
                          src={
                            obj.image
                              ? `${config.api.URL.replace('/api/v1', '')}/${obj.image}`
                              : TestimonialProfileDefault
                          }
                          alt=""
                          style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                        />
                      </li>
                    ))}
                </ol>

                {testimonials.length > 1 && (
                  <>
                    <a data-slide="prev" href="#quote-carousel" className="left carousel-control">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                    <a data-slide="next" href="#quote-carousel" className="right carousel-control">
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="services" className="services">
          <div className="container">
            <h2>OUR SERVICES</h2>
            <div className="row" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 paddingRight1">
                <img src={serviceImg2} width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>GoAgent for Importers</h3>
                    <p>
                      Post your clearing and transport jobs for reputable agents to bid. GoAgent
                      eliminates your risks and gives you best value for money.{' '}
                    </p>
                    <Link to="/importers-landing">Read More Here</Link>
                  </div>
                </div>
              </div>
              <div className="text-center mb-4">
                <button
                  type="button"
                  className="post-button"
                  onClick={() => props.history.push('/login')}
                >
                  Post a Clearing and Transport Job
                </button>
              </div>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 services-block2">
                <div className="services-block">
                  <div>
                    <h3>GoAgent for Clearing Agents</h3>
                    <p>
                      Are you a clearing agent looking for more jobs? We register importers who post
                      jobs daily. Start making more money with us today.{' '}
                    </p>
                    <Link to="/clearing-agents-landing">Read More Here</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 padding-left1">
                <img src={serviceImg3} width="100%" alt="" />
              </div>

              <div className="text-center home-action-btn">
                <button
                  type="button"
                  className="post-button"
                  onClick={() => props.history.push('/login')}
                >
                  Bid for a Clearing Job
                </button>
              </div>
            </div>
            <div className="row" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 paddingRight1">
                <img src={serviceImg4} width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>GoAgent for Transporters</h3>
                    <p>
                      Are you a transporter looking for more jobs? We register importers who post
                      jobs daily. Start making more money with us today.
                    </p>
                    <Link to="/forwarding-agents-landing">Read More Here</Link>
                  </div>
                </div>
              </div>

              <div style={{ textAlign: 'center' }}>
                <button
                  type="button"
                  className="post-button"
                  style={{ marginBottom: '0' }}
                  onClick={() => props.history.push('/login')}
                >
                  Bid for a Transport Job
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="our-values" className="our-values">
          <div className="container">
            <h2>OUR VALUES</h2>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6" style={{ padding: 0, marginLeft: '-15px' }}>
                <img src={CustomerOrientedImg} className="round-img" width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Customer Oriented</h3>
                    <p>
                      We are committed to continuously identifying the needs and requirements of our
                      internal and external customers, and we take the required action to meet the
                      needs effectively.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 services-block1-1">
                <div className="services-block">
                  <div>
                    <h3>Accountability</h3>
                    <p>
                      We assume responsibility for the delivery of high quality outcomes by honoring
                      our obligations to all stakeholders.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6" style={{ padding: 0, marginRight: '-15px' }}>
                <img src={AccountabilityImg} className="round-img" width="100%" alt="" />
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6" style={{ padding: 0, marginLeft: '-15px' }}>
                <img src={InnovationImg} className="round-img" width="100%" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Innovation</h3>
                    <p>
                      We constantly seek better ways to deliver service and create value in every
                      area of the business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6 services-block1-1">
                <div className="services-block">
                  <div>
                    <h3>Professionalism </h3>
                    <p>
                      We exercise high level of expertise and discipline in achieving the company’s
                      strategic objectives.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6" style={{ padding: '0' }}>
                <img src={ProfessionalismImg} width="100%" className="round-img" alt="" />
              </div>
            </div>
            <div className="row our-values-main-block" data-aos="fade-up">
              <div className="col-md-6 col-lg-6" style={{ padding: 0, marginLeft: '-15px' }}>
                <img src={StakeholderEngagementImg} width="100%" className="round-img" alt="" />
              </div>
              <div className="col-md-6 col-lg-6 services-block1">
                <div className="services-block">
                  <div>
                    <h3>Stakeholder Engagement</h3>
                    <p>
                      We identify, engage and manage the competing needs of all stakeholder-groups
                      for the betterment of the clearing and forwarding industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <ContactUsForm />
      <PublicFooter />
    </>
  );
};
export default Home;
