import { useFormik } from 'formik';
import { capitalize, startCase, camelCase } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as Yup from 'yup';

const vlidationSchema = Yup.object({
  destination: Yup.string().required('Please enter destination'),
  port_of_discharge: Yup.string().required('Please select port of discharge'),
  email: Yup.string()
    .email('Please enter valid email')
    .required('Please enter email'),
  phone: Yup.string(),
});

const TransportServiceTab = ({ onSubmit }) => {
  const serviceData = useSelector((state) => state.enumData);

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      destination: '',
      port_of_discharge: '',
      email: '',
      phone: '',
    },
    validationSchema: vlidationSchema,
    onSubmit: (values) => {
      values.job_type = 'transport';
      onSubmit(values, resetForm);
    },
  });

  return (
    <form onSubmit={handleSubmit} id="service-form">
      <Row>
        <Col md={6} lg={3}>
          <label htmlFor="port_of_discharge">Pickup Location</label>
          <select
            name="port_of_discharge"
            id="port_of_discharge"
            type="text"
            className="form-control"
            onChange={handleChange}
            value={values.port_of_discharge}
          >
            <option value="">Select Port</option>
            {serviceData?.ports?.length > 0 &&
              serviceData?.ports.map((obj) => (
                <option value={obj.uuid} key={obj.uuid}>
                  {startCase(camelCase(obj.port_of_operation))}
                </option>
              ))}
          </select>
          <span className="text-danger">
            {touched.port_of_discharge && errors.port_of_discharge}
          </span>
        </Col>

        <Col md={6} lg={3}>
          <label htmlFor="destination">Destination</label>
          <input
            name="destination"
            id="destination"
            type="text"
            className="form-control"
            onChange={handleChange}
            value={values.destination}
            placeholder="Enter your Destination"
          />
          <span className="text-danger">{touched.destination && errors.destination}</span>
        </Col>

        <Col md={6} lg={2}>
          <label htmlFor="email">Email</label>
          <input
            name="email"
            id="email"
            type="email"
            className="form-control"
            onChange={handleChange}
            value={values.email}
            placeholder="Enter your Email"
          />
          <span className="text-danger">{touched.email && errors.email}</span>
        </Col>

        <Col md={6} lg={2}>
          <label htmlFor="phone">Phone</label>
          <input
            name="phone"
            type="text"
            id="phone"
            className="form-control"
            onChange={handleChange}
            value={values.phone}
            placeholder="Enter phone number"
          />
        </Col>
        <Col md={6} lg={2}>
          <div className="text-right">
            <button className="post-button w-100" type="submit" disabled={!(isValid && dirty)}>
              Submit
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default TransportServiceTab;
