import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ErrorBoundary from '../components/global/ErrorBoundary';
import { readNotification } from '../redux/notification/NotificationActions';
import AppLoader from '../components/loaders/AppLoader';
import UnauthenticatUserHeader from '../components/global/UnauthenticatUserHeader';
import NotificationMenuItem from '../components/global/NotificationBadge';
import { NT_C_DISPUTES, NT_C_MY_JOBS, NT_C_PAYMENTS } from '../helpers/constants';
import UnauthenticateClientRejectedJobDetails from '../containers/PublicWebsite/UnauthenticateClientRejectedJobDetails';
import ClientRegSuccessModal from '../components/Modals/ClientRegSuccessModal';
import SuspensionModal from '../components/Modals/SuspensionModal';
import { hideDocumentModal, hideSuspensionModal } from '../redux/user/UserActions';
import DocumentModal from '../components/Modals/DocumentModal';

export const ValidateRoutes = ({ component: Component, has_permission, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        has_permission && has_permission !== undefined ? (
          <ErrorBoundary key={props.location.pathname}>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

const UnauthenticatClientRoute = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const userStoreState = useSelector((state) => state.user);
  const { user } = userStoreState;
  const [verficationModal, setVerificationModal] = useState(false);

  const [siderMemuToggle, setSiderMemuToggle] = useState(false);

  const toggleVerificationModal = () => setVerificationModal((s) => !s);

  // useEffect(() => {
  //   console.log('user', user);
  //   if (!isEmpty(user)) {
  //     toggleVerificationModal();
  //   }
  // }, [user]);

  const { match } = props;
  console.log(match.url);
  const { pathname } = props.location;

  return (
    <div id="client-page">
      <Helmet>
        <title>GoAgent</title>
      </Helmet>
      <UnauthenticatUserHeader />
      <main id="main" className="main-block">
        <div className="bodycont">
          <div style={{ clear: 'both' }}></div>

          <div className="tab">
            <div className="cormen-btn">
              <label htmlFor="cormid"></label>
            </div>
            <div className="cormen">
              <input
                id="cormid"
                type="checkbox"
                checked={siderMemuToggle}
                onChange={(e) => setSiderMemuToggle(e.target.checked)}
              />
              <ul>
                {user.identity_verified === 0 || user.identity_verified === 2 ? (
                  <button
                    className={`tablinks ${pathname.includes('/client/jobs') ? 'active' : ''}`}
                    onClick={() => {
                      props.history.push('/login');
                      toggleVerificationModal();
                    }}
                  >
                    <NotificationMenuItem title="My Jobs" types={NT_C_MY_JOBS} />
                  </button>
                ) : (
                  <button
                    className={`tablinks ${pathname.includes('/client/jobs') ? 'active' : ''}`}
                    onClick={() => {
                      props.history.push('/login');
                    }}
                  >
                    <NotificationMenuItem title="My Jobs" types={NT_C_MY_JOBS} />
                  </button>
                )}
                <button
                  className={`tablinks ${
                    pathname.includes('/client/profile') ||
                    pathname.includes('/client/edit-profile')
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => props.history.push('/login')}
                >
                  Profile
                </button>
                <button
                  className={`tablinks ${pathname.includes('/receipts') ? 'active' : ''}`}
                  onClick={() => props.history.push('/login')}
                >
                  <NotificationMenuItem title="Transaction History" types={NT_C_PAYMENTS} />
                </button>
                <button
                  className={`tablinks ${pathname.includes('/dispute') ? 'active' : ''}`}
                  onClick={() => props.history.push('/login')}
                >
                  <NotificationMenuItem title="Claims" types={NT_C_DISPUTES} />
                </button>
                {/* <button className={`tablinks ${pathname.includes('/messages') ? 'active' : ''}`} onClick={() => onMenuClick('/client/messages')}>
                  Message
                </button> */}
                <button
                  className={`tablinks ${pathname.includes('/notifications') ? 'active' : ''}`}
                  onClick={() => props.history.push('/login')}
                >
                  Messages
                </button>
              </ul>
            </div>
          </div>

          <Switch>
            <Route
              exact
              path={`${match.url}/jobs/:id`}
              component={UnauthenticateClientRejectedJobDetails}
            />

            {/* <Redirect from={match.url} to={`/`} /> */}
          </Switch>
          <div className="clearfix"></div>
        </div>

        <ClientRegSuccessModal
          isOpen={verficationModal}
          toggle={toggleVerificationModal}
          container="client-page"
        />
        {/* Modal to Display Suspension */}
        {userStoreState?.displaySuspensionModal && (
          <SuspensionModal
            container="client-page"
            isOpen={userStoreState.displaySuspensionModal}
            toggle={() => dispatch(hideSuspensionModal())}
          />
        )}
        {/* Modal to Display Document */}
        {userStoreState?.displayDocumentModal && (
          <DocumentModal
            isOpen={userStoreState.displayDocumentModal}
            toggle={() => dispatch(hideDocumentModal())}
            documentToShow={userStoreState.documentToDisplay}
            container="client-page"
          />
        )}
      </main>
    </div>
  );
};

export default UnauthenticatClientRoute;
